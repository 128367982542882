import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Backdrop, Button, Modal } from '@mui/material';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import SecondTypeButton from '../button/SecondTypeButton';
import { IDriverFetch } from 'src/models/IDriver';
import Icons from 'src/common/icons';

const DeleteModalForm: FC<{
  id: number;
  type: string;
  open: boolean;
  handleClose: () => void;
}> = ({ id, type, handleClose, open }) => {
  const {
    deleteVan,
    deleteClient,
    deletePaymentOrder,
    deleteOrder,
    updateOrder,
    updateDriver,
    deleteSeasonPrice,
    deleteNearVan,
    deleteHost,
    deleteHostStaff,
    deleteSublocation,
    deleteSubPrice,
    deleteAllSubPrices,
    deleteTag,
    updateLocation,
    deleteStripeId
  } = useActions();

  const state = useTypedSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  let action: any;
  let title = type;
  let result: string | number | undefined;
  switch (type) {
    case 'van':
      action = deleteVan;
      result = state.van.result.delete;
      break;
    case 'client':
      action = deleteClient;
      result = state.client.result.delete;
      break;
    case 'payment':
      action = deletePaymentOrder;
      break;
    case 'order':
      action = deleteOrder;
      result = state.order.result.delete;
      break;
    case 'season price':
      action = deleteSeasonPrice;
      result = state.seasonPrice.result.delete;
      break;
    case 'additional driver license':
      action = updateDriver;
      result = state.driver.result.update;
      break;
    case 'location nearby':
      action = deleteNearVan;
      break;
    case 'host staff':
      action = deleteHostStaff;
      break;
    case 'host':
      action = deleteHost;
      break;
    case 'sublocation':
      action = deleteSublocation;
      break;
    case 'sub price':
      action = deleteSubPrice;
      break;
    case 'sub prices':
      action = deleteAllSubPrices;
      break;
    case 'payment link':
      action = updateOrder;
      break;
    case 'tag':
      action = deleteTag;
      break;
    case 'location':
      action = updateLocation;
      break;
    case 'stripe connect':
      action = deleteStripeId;
      break;
    default:
      title = '';
      action = () => { };
      break;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-400'>
          <div className='modal__text-icon'>
            <div className='modal__icon'>
              <Icons.BigTrashBin />
            </div>
            <div className='modal__text'>
              {`Are you sure you want to delete this ${title}?`}
            </div>
          </div>
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={() => handleClose()}
              sx={{ marginTop: '30px' }}
            />
            <Button
              children='Delete'
              variant='contained'
              onClick={() => {
                enqueueSnackbar(`Deleting ${type}...`);
                type === 'additional driver license'
                  ? action({ driver_license_id: null } as IDriverFetch, id)
                  : type === 'payment link' ? action({ id: id, payment_link: '' })
                    : type === 'location' ? action({ isDelete: true }, id)
                      : action(id);
                handleClose();
                if (result) {
                  navigate(`/${type}`);
                }
              }}
              sx={{ marginTop: '30px' }}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default DeleteModalForm;
