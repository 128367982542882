import { IPagination } from 'src/models/IPagination';
import {
  IManualItemOrderFetch,
  IOrderFetch,
  ICharge,
  IPartialRefund,
  IPayOrderTypeName,
  IPartialPayOrderTypeName,
  IOrderChangeDates
} from 'src/models/IOrder';

export enum OrderNotificationToGuestAutomaticallyEnum {
  'confirmation',
  'Upload Docs/DL',
  'invite driver',
  'Pay Balance',
  'Sign RA after docs',
  'pick-up details',
  'Send keys code',
  'return reminder',
  'get review'
}

export enum OrderNotificationToGuestAdditionEnum {
  'confirm EDITS / changes',
  'docs reminder',
  'delivery pickup fee',
  'Van changed. 1st move',
  'Pick-Up/Return Brief',
  'CANCEL for guest (no delete)',
  "sorry, no van (does't delete)"
}

export enum OrderNotificationToHostsEnum {
  'Host | Remind Host',
  'Host | Guest canceled',
  'Host | make invoice',
  'Guest Wants Coverage',
}

export enum OrderNotificationToLeadsEnum {
  'Ask Lead 4 down payment',
}

export enum OrderActionEnum {
  REQUEST_ORDERS = 'REQUEST_ORDERS',
  RESULT_ORDERS = 'RESULT_ORDERS',
  ERROR_ORDERS = 'ERROR_ORDERS',

  REQUEST_ORDER = 'REQUEST_ORDER',
  RESULT_ORDER = 'RESULT_ORDER',
  ERROR_ORDER = 'ERROR_ORDER',
  CLEAR_ORDER = 'CLEAR_ORDER',

  REQUEST_ADD_ORDER = 'REQUEST_ADD_ORDER',
  RESULT_ADD_ORDER = 'RESULT_ADD_ORDER',

  REQUEST_UPDATE_ORDER = 'REQUEST_UPDATE_ORDER',
  REQUEST_UPDATE_EXTRA_ORDER = 'REQUEST_UPDATE_EXTRA_ORDER',
  RESULT_UPDATE_ORDER = 'RESULT_UPDATE_ORDER',

  REQUEST_UPDATE_PRICE_ORDER = 'REQUEST_UPDATE_PRICE_ORDER',
  RESULT_UPDATE_PRICE_ORDER = 'RESULT_UPDATE_PRICE_ORDER',

  REQUEST_ADD_PAYMENT_ORDER = 'REQUEST_ADD_PAYMENT_ORDER',
  RESULT_ADD_PAYMENT_ORDER = 'RESULT_ADD_PAYMENT_ORDER',

  REQUEST_ADD_PARTIAL_PAYMENT_ORDER = 'REQUEST_ADD_PARTIAL_PAYMENT_ORDER',
  RESULT_ADD_PARTIAL_PAYMENT_ORDER = 'RESULT_ADD_PARTIAL_PAYMENT_ORDER',

  REQUEST_UPDATE_PAYMENT_ORDER = 'REQUEST_UPDATE_PAYMENT_ORDER',
  RESULT_UPDATE_PAYMENT_ORDER = 'RESULT_UPDATE_PAYMENT_ORDER',

  REQUEST_DELETE_PAYMENT_ORDER = 'REQUEST_DELETE_PAYMENT_ORDER',
  RESULT_DELETE_PAYMENT_ORDER = 'RESULT_DELETE_PAYMENT_ORDER',

  REQUEST_REFUND_PAYMENT_ORDER = 'REQUEST_REFUND_PAYMENT_ORDER',
  RESULT_REFUND_PAYMENT_ORDER = 'RESULT_REFUND_PAYMENT_ORDER',

  REQUEST_TRANSFER_PAYMENT = 'REQUEST_TRANSFER_PAYMENT',
  RESULT_TRANSFER_PAYMENT = 'RESULT_TRANSFER_PAYMENT',

  REQUEST_AFTER_CHARGE_PAYMENT = 'REQUEST_AFTER_CHARGE_PAYMENT',
  RESULT_AFTER_CHARGE_PAYMENT = 'RESULT_AFTER_CHARGE_PAYMENT',

  REQUEST_DELETE_ORDER = 'REQUEST_DELETE_ORDER',
  RESULT_DELETE_ORDER = 'RESULT_DELETE_ORDER',

  REQUEST_ACTION_ORDER = 'REQUEST_ACTION_ORDER',
  RESULT_ACTION_ORDER = 'RESULT_ACTION_ORDER',

  REQUEST_AFTERTRIPCHARGES = 'REQUEST_AFTERTRIPCHARGES',
  RESULT_AFTERTRIPCHARGES = 'RESULT_AFTERTRIPCHARGES',

  REQUEST_SHOW_CONTRACT = 'REQUEST_SHOW_CONTRACT',
  RESULT_SHOW_CONTRACT = 'RESULT_SHOW_CONTRACT',
  REQUEST_SHOW_LICENSE = 'REQUEST_SHOW_LICENSE',
  RESULT_SHOW_LICENSE = 'RESULT_SHOW_LICENSE',
  REQUEST_SHOW_INSURANCE = 'REQUEST_SHOW_INSURANCE',
  RESULT_SHOW_INSURANCE = 'RESULT_SHOW_INSURANCE',

  REQUEST_CHANGE_DATES = 'REQUEST_CHANGE_DATES',
  RESULT_CHANGE_DATES = 'RESULT_CHANGE_DATES'
}

export enum OrderNotificationActionEnum {
  REQUEST_EMAIL = 'REQUEST_EMAIL',
  RESULT_EMAIL = 'RESULT_EMAIL',

  REQUEST_SMS = 'REQUEST_SMS',
  RESULT_SMS = 'RESULT_SMS',
  REQUEST_COPY_SMS = 'REQUEST_COPY_SMS',
  RESULT_COPY_SMS = 'RESULT_COPY_SMS',
  SEND_SMS = 'SEND_SMS',
  RESULT_SEND_SMS = 'RESULT_SEND_SMS',

  INIT_NOTIFICATION = 'INIT_NOTIFICATION',
}

export enum OrderPaymentLinkActionEnum {
  REQUEST_PAYMENTLINK = 'REQUEST_PAYMENTLINK',
  RESULT_PAYMENTLINK = 'RESULT_PAYMENTLINK',
}

export enum EnumNotification {
  email = 'email',
  sms = 'sms',
}

export interface Base64Object {
  fileName: string;
  fileType: string;
  base64: string;
}

export interface INotification {
  orderId: number;
  status: number;
  text?: string;
  type:
  | OrderNotificationToLeadsEnum
  | OrderNotificationToGuestAdditionEnum
  | OrderNotificationToHostsEnum
  | OrderNotificationToGuestAutomaticallyEnum
  | string
  | null;
}
export interface OrderState {
  orderList: IOrderFetch[];
  order?: IOrderFetch;
  readonly total?: number;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
    payment: boolean;
    action: boolean;
    refund: boolean;
    afterCharge: boolean;
    transfer: boolean;
    doc: boolean;
  };
  result: {
    add?: number | string;
    delete?: number | string;
    update?: number | string;
    payment?: number | string;
    action?: number | string;
    refund?: number | string;
    afterCharge?: { data?: string, status?: number };
    transfer?: { data?: string, status?: number };
    doc?: Base64Object;
  };
  error: {
    get?: number | string;
    add?: number | string;
    delete?: number | string;
    update?: number | string;
    payment?: number | string;
    action?: number | string;
    refund?: number | string;
    transfer?: number | string;
  };
  notification: {
    result: {
      email?: INotification;
      sms?: number | string;
      smsText?: {
        data?: string;
        status?: number | string;
      };
    };
    isLoading: {
      email: boolean;
      sms: boolean;
      sendSms: boolean;
    };
  };
  paymentLink: {
    result: {
      get?: string;
    };
    isLoading: {
      get: boolean;
    };
  };
  afterTripCharges: {
    result: {
      get?: ICharge[];
    };
    isLoading: {
      get: boolean;
    };
  };
  action: {
    data?: string | IOrderFetch;
  };
}

export interface NotificationEmailOrderAction {
  type: OrderNotificationActionEnum.REQUEST_EMAIL;
  orderId: number;
  typeNotification:
  | OrderNotificationToLeadsEnum
  | OrderNotificationToGuestAdditionEnum
  | OrderNotificationToHostsEnum
  | OrderNotificationToGuestAutomaticallyEnum
  | string
  driverId?: number;
  handlePaymentId?: number;
}
export interface ResultNotificationEmailOrderAction {
  type: OrderNotificationActionEnum.RESULT_EMAIL;
  status: number;
  orderId: number;
  typeNotification:
  | OrderNotificationToLeadsEnum
  | OrderNotificationToGuestAdditionEnum
  | OrderNotificationToHostsEnum
  | OrderNotificationToGuestAutomaticallyEnum
  | string
}
export interface NotificationSmsOrderAction {
  type: OrderNotificationActionEnum.REQUEST_SMS;
  orderId: number;
  typeNotification:
  | OrderNotificationToLeadsEnum
  | OrderNotificationToGuestAdditionEnum
  | OrderNotificationToHostsEnum
  | OrderNotificationToGuestAutomaticallyEnum
}
export interface ResultNotificationSmsOrderAction {
  type: OrderNotificationActionEnum.RESULT_SMS;
  status: number;
}
export interface NotificationCopySmsOrderAction {
  type: OrderNotificationActionEnum.REQUEST_COPY_SMS;
  orderId: number;
  typeNotification:
  | OrderNotificationToLeadsEnum
  | OrderNotificationToGuestAdditionEnum
  | OrderNotificationToHostsEnum
  | OrderNotificationToGuestAutomaticallyEnum
}
export interface ResultNotificationCopySmsOrderAction {
  type: OrderNotificationActionEnum.RESULT_COPY_SMS;
  status: number;
  data?: string;
}

export interface InitNotificationOrderAction {
  type: OrderNotificationActionEnum.INIT_NOTIFICATION;
}

export type OrderNotificationAction =
  | NotificationEmailOrderAction
  | ResultNotificationEmailOrderAction
  | NotificationSmsOrderAction
  | ResultNotificationSmsOrderAction
  | InitNotificationOrderAction;

export interface GetPaymentLinkAction {
  type: OrderPaymentLinkActionEnum.REQUEST_PAYMENTLINK;
  orderId: number;
}
export interface ResultGetPaymentLinkAction {
  type: OrderPaymentLinkActionEnum.RESULT_PAYMENTLINK;
  link: string;
}

export type OrderPaymentLinkAction =
  | GetPaymentLinkAction
  | ResultGetPaymentLinkAction;

export interface AddPaymentOrderAction extends IPayOrderTypeName {
  type: OrderActionEnum.REQUEST_ADD_PAYMENT_ORDER;
}
export interface ResultAddPaymentOrderAction {
  type: OrderActionEnum.RESULT_ADD_PAYMENT_ORDER;
  status: number;
}
export interface AddPartialPaymentOrderAction extends IPartialPayOrderTypeName {
  type: OrderActionEnum.REQUEST_ADD_PARTIAL_PAYMENT_ORDER;
}
export interface ResultAddPartialPaymentOrderAction {
  type: OrderActionEnum.RESULT_ADD_PARTIAL_PAYMENT_ORDER;
  status: number;
}
export interface UpdatePaymentOrderAction {
  type: OrderActionEnum.REQUEST_UPDATE_PAYMENT_ORDER;
  order_id: number;
  charges: ICharge[];
}
export interface ResultUpdatePaymentOrderAction {
  type: OrderActionEnum.RESULT_UPDATE_PAYMENT_ORDER;
  data: string;
  status: number;
}
export interface DeletePaymentOrderAction {
  type: OrderActionEnum.REQUEST_DELETE_PAYMENT_ORDER;
  id: number;
}
export interface ResultDeletePaymentOrderAction {
  type: OrderActionEnum.RESULT_DELETE_PAYMENT_ORDER;
  status: number;
}

export interface RefundPaymentOrderAction {
  type: OrderActionEnum.REQUEST_REFUND_PAYMENT_ORDER;
  data: IPartialRefund;
}
export interface ResultRefundPaymentOrderAction {
  type: OrderActionEnum.RESULT_REFUND_PAYMENT_ORDER;
  status: number;
}

export interface TransferPaymentAction {
  type: OrderActionEnum.REQUEST_TRANSFER_PAYMENT;
  id: number;
}
export interface ResultTransferPaymentAction {
  type: OrderActionEnum.RESULT_TRANSFER_PAYMENT;
  status: number;
  data?: string;
}

export interface AfterChargePaymentAction {
  type: OrderActionEnum.REQUEST_AFTER_CHARGE_PAYMENT;
  order_id: number,
  amount: number,
  note: string
}
export interface ResultAfterChargePaymentAction {
  type: OrderActionEnum.RESULT_AFTER_CHARGE_PAYMENT;
  data: string;
  status: number;
}

export interface GetOrdersAction {
  type: OrderActionEnum.REQUEST_ORDERS;
  filter: IPagination;
}
export interface ResultGetOrdersAction {
  type: OrderActionEnum.RESULT_ORDERS;
  orderList: IOrderFetch[];
  count: number;
}
export interface GetOrderAction {
  type: OrderActionEnum.REQUEST_ORDER;
  id: number;
}
export interface ErrorGetOrderAction {
  type: OrderActionEnum.ERROR_ORDER;
  status: number;
}
export interface ResultGetOrderAction {
  type: OrderActionEnum.RESULT_ORDER;
  data: IOrderFetch;
}
export interface AddOrderAction {
  type: OrderActionEnum.REQUEST_ADD_ORDER;
  currentOrder: IOrderFetch;
}
export interface ResultAddOrderAction {
  type: OrderActionEnum.RESULT_ADD_ORDER;
  status: number;
}
export interface UpdateOrderAction {
  type: OrderActionEnum.REQUEST_UPDATE_ORDER;
  currentOrder: Partial<IOrderFetch>;
}
export interface ResultUpdateOrderAction {
  type: OrderActionEnum.RESULT_UPDATE_ORDER;
  status: number;
}
export interface DeleteOrderAction {
  type: OrderActionEnum.REQUEST_DELETE_ORDER;
  id: number;
}
export interface ResultDeleteOrderAction {
  type: OrderActionEnum.RESULT_DELETE_ORDER;
  status: number;
}
export interface UpdatePriceOrderAction {
  type: OrderActionEnum.REQUEST_UPDATE_PRICE_ORDER;
  data: IManualItemOrderFetch;
}
export interface UpdateExtraOrderAction {
  type: OrderActionEnum.REQUEST_UPDATE_EXTRA_ORDER;
  currentOrder: Pick<IOrderFetch, 'id' | 'extras'>;
}
export interface ClearOrderAction {
  type: OrderActionEnum.CLEAR_ORDER;
}

export interface ActionOrderAction {
  type: OrderActionEnum.REQUEST_ACTION_ORDER;
  id: number;
  actionType: string;
}
export interface ResultActionOrderAction {
  type: OrderActionEnum.RESULT_ACTION_ORDER;
  data?: any;
  status: number;
}

export interface RequestAfterTripChargesAction {
  type: OrderActionEnum.REQUEST_AFTERTRIPCHARGES;
}

export interface ResultAfterTripChargesAction {
  type: OrderActionEnum.RESULT_AFTERTRIPCHARGES;
  data: ICharge[];
}

export interface ShowContractAction {
  type: OrderActionEnum.REQUEST_SHOW_CONTRACT;
  key: string;
}
export interface ResultShowContractAction {
  type: OrderActionEnum.RESULT_SHOW_CONTRACT;
  data: Base64Object;
}

export interface ShowLicenseAction {
  type: OrderActionEnum.REQUEST_SHOW_LICENSE;
  key: string;
}
export interface ResultShowLicenseAction {
  type: OrderActionEnum.RESULT_SHOW_LICENSE;
  data: Base64Object;
}

export interface ShowInsuranceAction {
  type: OrderActionEnum.REQUEST_SHOW_INSURANCE;
  key: string;
}
export interface ResultShowInsuranceAction {
  type: OrderActionEnum.RESULT_SHOW_INSURANCE;
  data: Base64Object;
}

export interface ChangeOrderDatesAction {
  type: OrderActionEnum.REQUEST_CHANGE_DATES;
  dates: IOrderChangeDates,
  orderId: number
}
export interface ResultChangeOrderDatesAction {
  type: OrderActionEnum.RESULT_CHANGE_DATES;
  status: number,
  data?: IOrderFetch
}

export type OrderAction =
  | GetOrdersAction
  | ResultGetOrdersAction
  | GetOrderAction
  | ResultGetOrderAction
  | ErrorGetOrderAction
  | AddOrderAction
  | ResultAddOrderAction
  | UpdateOrderAction
  | UpdatePriceOrderAction
  | UpdateExtraOrderAction
  | ResultUpdateOrderAction
  | DeleteOrderAction
  | ResultDeleteOrderAction
  | AddPaymentOrderAction
  | ResultAddPaymentOrderAction
  | AddPartialPaymentOrderAction
  | ResultAddPartialPaymentOrderAction
  | UpdatePaymentOrderAction
  | ResultUpdatePaymentOrderAction
  | DeletePaymentOrderAction
  | ResultDeletePaymentOrderAction
  | ClearOrderAction
  | ActionOrderAction
  | ResultActionOrderAction
  | RequestAfterTripChargesAction
  | ResultAfterTripChargesAction
  | NotificationCopySmsOrderAction
  | ResultNotificationCopySmsOrderAction
  | RefundPaymentOrderAction
  | ResultRefundPaymentOrderAction
  | AfterChargePaymentAction
  | ResultAfterChargePaymentAction
  | TransferPaymentAction
  | ResultTransferPaymentAction
  | ShowContractAction
  | ResultShowContractAction
  | ShowLicenseAction
  | ResultShowLicenseAction
  | ShowInsuranceAction
  | ResultShowInsuranceAction
  | ChangeOrderDatesAction
  | ResultChangeOrderDatesAction;
