import { FC, useEffect, useState } from 'react';
import { Backdrop, Checkbox, CircularProgress, Modal } from '@mui/material';
import SearchInput from 'src/components/Items/input/SearchInput';
import ModalAction from 'src/components/Items/modal/Actions';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import './styles.scss';

interface IProps {
  open: boolean;
  handleClose: () => void;
  orderId: number;
}

const SearchClientModal: FC<IProps> = ({ open, handleClose, orderId }) => {

  const { updateOrder, searchClients } = useActions();
  const { isLoading, result } = useTypedSelector((state) => state.client);
  const [search, setSearch] = useState('');
  const [clientId, setClientId] = useState(0);

  useEffect(() => {
    search && searchClients(search);
  }, [search]);

  const handleCloseModal = () => {
    setSearch('');
    handleClose();
  };

  const handleSubmit = () => {
    clientId && updateOrder({ id: orderId, client_id: clientId });
    setSearch('');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-400 search-modal'>
          <div className='modal__title'>Search Client</div>
          <SearchInput
            setSearch={setSearch}
            placeholder='Enter email'
            width={'100%'}
          />
          {isLoading.search &&
            <div className='search-modal__circular'>
              <CircularProgress size={50} />
            </div>
          }
          {(result.search && !isLoading.search && search) ? (
            <>
              <div className='search-modal__clients'>
                {result.search.map((client) => (
                  <div className='search-modal__client'>
                    <div>{client.email}</div>
                    <Checkbox
                      checked={clientId === client.id}
                      onChange={() => setClientId(client.id)}
                      size="small"
                    />
                  </div>
                ))}
              </div>
              <ModalAction
                handleSubmit={handleSubmit}
                handleClose={handleCloseModal}
                type='client'
                action='Update'
              />
            </>
          ) : (
            <SecondTypeButton
              children='Cancel'
              onClick={handleCloseModal}
              className='search-modal__cancel'
            />
          )}
        </div>
      </>
    </Modal>
  );
};
export default SearchClientModal;
