import { Backdrop, Modal } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ModalAction from "src/components/Items/modal/Actions";
import { Van } from "src/entity/Van";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import VanFormModal from "../Form";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const AddVanModal: FC<IProps> = ({ open, handleClose }) => {
  const {
    auth: { role, id },
    van: { result }
  } = useTypedSelector((state) => state);
  const addedVanId = useTypedSelector((state) => state.van.van?.id);
  const { addVanImage, addVan } = useActions();
  const [van, setVan] = useState(new Van());
  const [interiorImages, setInteriorImages] = useState<File[]>([]);
  const [exteriorImages, setExteriorImages] = useState<File[]>([]);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  const handleInput = (name: string) => (value: string | number) => {
    name === 'isVirtual' ? (
      setVan({ ...van, 'isVirtual': !van.isVirtual })
    ) : (
      setVan({ ...van, [name]: value })
    );
  };

  const vanModel = {
    ...van,
    host_id: role === "admin" ? van.host_id : id,
    price: van.price * 100,
  };

  useEffect(() => {
    return () => {
      setExteriorImages([]);
      setInteriorImages([]);
    };
  }, []);

  useEffect(() => {
    (result.add === 200 || result.add === 201) &&
      addVanImage(
        addedVanId!,
        interiorImages,
        exteriorImages
      );
  }, [result.add]);

  const handleSubmit = () => {
    addVan(vanModel);
    setVan(new Van());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className="modal modal-1000">
          <div className="modal__title">Add Van</div>
          <VanFormModal
            van={van}
            setVan={handleInput}
            interiorImages={interiorImages}
            setInteriorImages={setInteriorImages}
            exteriorImages={exteriorImages}
            setExteriorImages={setExteriorImages}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
          />
          <ModalAction
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            type="van"
            action="Add"
          />
        </div>
      </>
    </Modal>
  );
};
export default AddVanModal;
