import { put, takeLatest } from "redux-saga/effects";
import { OrderActionEnum } from "src/store/reducers/order/types";
import instance from "../set";
import { OrderActionCreators } from "src/store/reducers/order/action-creators";

function* fetchChangeDates({
  dates,
  orderId
}: ReturnType<typeof OrderActionCreators.changeOrderDates>) {
  try {
    const { status, data } = yield instance.patch(`/order/date-price/${orderId}`, {
      'data': dates
    });
    yield put(OrderActionCreators.resultChangeOrderDates(status, data));
  } catch (e) {
    console.log(e);
    yield put(OrderActionCreators.resultChangeOrderDates(400));
  }
}

export function* actionWatcherChangeOrderDates() {
  yield takeLatest(OrderActionEnum.REQUEST_CHANGE_DATES, fetchChangeDates);
}