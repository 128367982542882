import { FC, useEffect, useState } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import PickupDetailsFormModal from '../AddPickupDetails/PickupDetailsForm';
import { useActions } from 'src/hooks/useActions';
import { validateField } from 'src/common/utils/validation';
import { IPickupDetailsFetch } from 'src/models/IPickupDetails';

interface IProps {
    open: boolean;
    handleClose: () => void;
    editPickupDetails: IPickupDetailsFetch;
}

export type IErrorsPdData = {
    location?: string | undefined;
    return_at?: string | undefined;
    description?: string | undefined;
    host_id?: string | undefined;
    for_all?: boolean | undefined;
};

const EditPickupDetailsModal: FC<IProps> = ({ open, handleClose, editPickupDetails }) => {

    const { updatePickupDetails } = useActions();
    const [pd, setPd] = useState<IPickupDetailsFetch>(editPickupDetails);
    const [mainLocation, setMainLocation] = useState('');
    const [check, setCheck] = useState(false);
    const [errors, setErrors] = useState<IErrorsPdData>({});
    const [disable, setDisable] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setPd(editPickupDetails);
    }, [editPickupDetails]);

    const handleInput = (name: string) => (value: any) => {
        name === 'location' ? (
            value.location ? (
                setPd({ ...pd, 'location': value.name }),
                setMainLocation(value.location)
            ) : (
                setPd({ ...pd, [name]: value }),
                setMainLocation('')
            )
        ) : (
            setPd({ ...pd, [name]: value })
        );
    };

    function chengeCheckbox() {
        setPd({ ...pd, 'for_all': !pd.for_all });
    }

    useEffect(() => {
        pd.for_all ? (
            setDisable(true),
            setPd({ ...pd, 'host_id': null })
        ) : setDisable(false);
    }, [pd.for_all]);

    useEffect(() => {
        JSON.stringify(editPickupDetails) === JSON.stringify(pd) ?
            setDisableButton(true) : setDisableButton(false);
    }, [pd]);

    const validateInputs = (): boolean => {
        const location = validateField(pd.location);
        const return_at = validateField(pd.return_at);
        const description = validateField(pd.description);
        const host_id = pd.for_all ? '' : (pd.host_id === null ? 'Field can not be empty' : '');

        setErrors({
            location,
            return_at,
            host_id,
            description
        });
        return [location, return_at, host_id, description].every(
            (el) => !el
        );
    };

    const handleCloseModal = () => {
        setPd(editPickupDetails);
        setCheck(false);
        setErrors({});
        handleClose();
    };

    const handleSubmit = () => {
        setCheck(true);
        if (validateInputs()) {
            setPd(editPickupDetails);
            setCheck(false);
            setErrors({});
            handleCloseModal();

            const updatedPd = pd;
            for (const key in pd) {
                (pd[key as keyof typeof pd] === editPickupDetails[key as keyof typeof editPickupDetails] ||
                    pd[key as keyof typeof pd] === ''
                ) && delete updatedPd[key as keyof typeof updatedPd];
            }
            mainLocation && updatedPd.location ? (
                updatedPd.location = `${mainLocation}__${updatedPd.location}`,
                updatePickupDetails(updatedPd as IPickupDetailsFetch, editPickupDetails.id)
            ) : (
                updatePickupDetails(updatedPd as IPickupDetailsFetch, editPickupDetails.id)
            );
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            BackdropComponent={Backdrop}
            closeAfterTransition
        >
            <div className='modal modal-850'>
                <div className='modal__title'>Edit Pickup Details</div>
                <PickupDetailsFormModal
                    pd={pd}
                    setPd={handleInput}
                    check={check}
                    errors={errors}
                    disable={disable}
                />
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '-5px' }}>
                    <CustomCheckbox
                        onChange={chengeCheckbox}
                        checked={pd.for_all ? pd.for_all : false}
                        name={'for all'}
                    />
                    <p className='filter__title' style={{ marginBottom: 0, marginLeft: '10px' }}>Default</p>
                </div>
                <div className='col-2'>
                    <SecondTypeButton
                        children='Cancel'
                        onClick={handleCloseModal}
                        sx={{ marginTop: '30px' }}
                    />
                    <Button
                        sx={{ marginTop: 3.5 }}
                        variant='contained'
                        onClick={handleSubmit}
                        children={'Edit Pickup Details'}
                        disabled={disableButton}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default EditPickupDetailsModal;
