import { FC } from 'react';
import { Backdrop, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../input/SearchInput';

interface IProps {
  open: any;
  handleClose: () => void;
}

const SearchModal: FC<IProps> = ({ handleClose, open }) => {

  const navigate = useNavigate();

  const handleClick = (orderNumber: string) => {
    if (orderNumber !== '') {
      navigate(`/order/${orderNumber}`);
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div
          className='modal modal-400'
          style={{ padding: '20px' }}
        >
          <SearchInput
            setSearch={handleClick}
            placeholder='Enter order number'
            width={'100%'}
          />
        </div>
      </>
    </Modal>
  );
};
export default SearchModal;
