import { FC } from 'react';
import { DateTime } from 'luxon';
import { CircularProgress, IconButton, Tooltip, styled } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SmsIcon from '@mui/icons-material/Sms';
import useIsLaptop from 'src/hooks/useIsLaptop';
import { useActions } from 'src/hooks/useActions';
import { EnumHelpers } from 'src/common/utils/others';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import {
  OrderNotificationToGuestAdditionEnum,
  OrderNotificationToLeadsEnum,
  OrderNotificationToGuestAutomaticallyEnum,
  OrderNotificationToHostsEnum,
} from 'src/store/reducers/order/types';
import './styles.scss';

interface IProps {
  orderId: number;
  order: string[];
  due: number;
  status: string;
  OrderNotificationEnum: typeof OrderNotificationToLeadsEnum
  | typeof OrderNotificationToGuestAdditionEnum
  | typeof OrderNotificationToHostsEnum
  | typeof OrderNotificationToGuestAutomaticallyEnum;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 23,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const OrderNotification: FC<IProps> = ({
  orderId,
  order,
  due,
  status,
  OrderNotificationEnum,
}) => {
  const { isLoading } = useTypedSelector(
    (state) => state.order.notification
  );
  const { auth } = useTypedSelector((state) => state);
  const { sentEmail, sendSms, copySms } = useActions();
  const isLaptop = useIsLaptop();

  const notificationEmailCounter = (title: string) => {
    const counter = order.filter((item) => item === title).length;
    return counter;
  };

  const notificationSmsCounter = (title: string) => {
    const counter = order.filter((item) => item === `${title}_sms`).length;
    return counter;
  };

  const handleSentNotification =
    (typeNotification: any, notification: keyof typeof OrderNotificationEnum) => () =>
      typeNotification(orderId, notification);
  let notification = EnumHelpers.getNames(OrderNotificationEnum);
  if (auth.role === 'host') {
    notification = EnumHelpers.getNames(OrderNotificationEnum).filter(
      (i) => i !== 'get review'
    );
  }

  const handleSendSms = (notification: keyof typeof OrderNotificationEnum) => sendSms(orderId, notification);
  const handleCopySmsText = (notification: keyof typeof OrderNotificationEnum) => copySms(orderId, notification);

  return (
    <>
      {notification.map((notification, i) => (
        <div key={i} className='order-notification__list__item'>
          <Tooltip title={
            (isLaptop && (notification.includes(' (')) || notification.length > 23) ? notification : ''
          }
          >
            <>
              {
                (isLaptop) ?
                  (notification.includes(' (') ? `${notification.split(' (')[0]}...` :
                    notification.length > 23 ? `${notification.slice(0, 23)}...`
                      : notification) : notification
              }
            </>
          </Tooltip>
          <div className='order-notification__action'>
            <div className='order-notification__action__item'>
              {isLoading.email ? (
                <CircularProgress size={15} />
              ) : (
                <Tooltip title='Send email'>
                  <IconButton
                    onClick={handleSentNotification(
                      sentEmail,
                      notification as keyof typeof OrderNotificationEnum
                    )}
                    disabled={
                      (notification === 'Sign RA after docs' && status !== 'paid')
                      || (notification === 'Pay Balance' && due === 0)
                    }
                  >
                    <StyledBadge badgeContent={notificationEmailCounter(notification)}>
                      <AlternateEmailIcon />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className='order-notification__action__item'>
              {isLoading.sms ? (
                <CircularProgress size={15} />
              ) : (
                <Tooltip title='Send sms'>
                  <IconButton
                    onClick={() =>
                      handleSendSms(notification as keyof typeof OrderNotificationEnum)
                    }
                    disabled={
                      (notification === 'Sign RA after docs' && status !== 'paid')
                      || (notification === 'Pay Balance' && due === 0)
                    }
                  >
                    <StyledBadge badgeContent={notificationSmsCounter(notification)}>
                      <SmsIcon />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className='order-notification__action__item'>
              {isLoading.sendSms ? (
                <CircularProgress size={15} />
              ) : (
                <Tooltip title='Copy sms text'>
                  <IconButton
                    onClick={() =>
                      handleCopySmsText(notification as keyof typeof OrderNotificationEnum)
                    }
                    disabled={
                      (notification === 'Sign RA after docs' && status !== 'paid')
                      || (notification === 'Pay Balance' && due === 0)
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OrderNotification;
