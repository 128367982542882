import { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { useActions } from 'src/hooks/useActions';
import { ISubLocationFetch } from 'src/models/ILocation';

interface IProps {
    sublocation: ISubLocationFetch;
    close: boolean;
    handleClose: () => void;
}

const EditSublocationModal: FC<IProps> = ({ handleClose, sublocation, close }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { updateSublocation } = useActions();

    const [item, setItem] = useState(sublocation.name);
    const [check, setCheck] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = () => {
        !item && setIsError(true);
        if (isError) setCheck(true);
        else {
            enqueueSnackbar('Editing Sublocations...');
            updateSublocation(item, sublocation.id);
            setItem('');
            handleClose();
        }
    };

    return (
        <Modal
            open={close}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            closeAfterTransition
        >
            <div className='modal modal-400 modal-tag'>
                <div className='modal__title'>Sublocations</div>
                <div className='modal__fields'>
                    <SimpleInput
                        data={item}
                        setData={setItem}
                        placeholder='sublocation'
                        check={check}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className='col-2'>
                    <SecondTypeButton
                        children='Cancel'
                        onClick={handleClose}
                        sx={{ marginTop: '30px' }}
                    />
                    <Button
                        variant='contained'
                        children='Save'
                        onClick={handleSubmit}
                        sx={{ marginTop: '30px' }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default EditSublocationModal;
