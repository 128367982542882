import { FC, useState } from 'react';
import { Modal } from '@mui/material';
import Store from './../Store';
import { IOrderFetch } from 'src/models/IOrder';

interface IProps {
  open: boolean;
  handleClose: () => void;
  order: IOrderFetch;
}

const ArticlesModal: FC<IProps> = ({ order, open, handleClose }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Store
        orderData={order}
        handleClose={handleClose}
      />
    </Modal>
  );
};

export default ArticlesModal;