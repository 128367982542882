import { IHost, IHostFetch } from "src/models/IHost";

export enum HostActionEnum {
  REQUEST_HOSTS = "REQUEST_HOSTS",
  RESULT_HOSTS = "RESULT_HOSTS",
  ERROR_HOSTS = "ERROR_HOSTS",

  REQUEST_HOST = "REQUEST_HOST",
  RESULT_HOST = "RESULT_HOST",

  REQUEST_ADD_HOST = "REQUEST_ADD_HOST",
  RESULT_ADD_HOST = "RESULT_ADD_HOST",

  REQUEST_UPDATE_HOST = "REQUEST_UPDATE_HOST",
  RESULT_UPDATE_HOST = "RESULT_UPDATE_HOST",

  REQUEST_DELETE_HOST = "REQUEST_DELETE_HOST",
  RESULT_DELETE_HOST = "RESULT_DELETE_HOST",

  REQUEST_DELETE_STRIPE_ID = 'REQUEST_DELETE_STRIPE_ID',
  RESULT_DELETE_STRIPE_ID = 'RESULT_DELETE_STRIPE_ID',

  CLEAR_HOST_RESULT = 'CLEAR_HOST_RESULT'
}

export interface HostState {
  hostList: IHostFetch[];
  total: number | null;
  isLoading: {
    all: boolean;
    get: boolean;
    add: boolean;
    delete: boolean;
    update: boolean;
    deleteStripe: boolean;
  };
  result: {
    all?: number | string;
    add?: number | string;
    update?: number | string;
    delete?: number | string;
    deleteStripe?: number | string;
  };
}

export interface GetHostsAction {
  type: HostActionEnum.REQUEST_HOSTS;
}

export interface ResultGetHostsAction {
  type: HostActionEnum.RESULT_HOSTS;
  data: IHostFetch[];
  status: number;
}

export interface ErrorHostAction {
  type: HostActionEnum.ERROR_HOSTS;
  payload?: any;
}

export interface AddHostAction {
  type: HostActionEnum.REQUEST_ADD_HOST;
  data: IHost;
}
export interface ResultAddHostAction {
  type: HostActionEnum.RESULT_ADD_HOST;
  status: number;
}

export interface UpdateHostAction {
  type: HostActionEnum.REQUEST_UPDATE_HOST;
  currentHost: IHostFetch;
  id: string | number
}
export interface ResultUpdateHostAction {
  type: HostActionEnum.RESULT_UPDATE_HOST;
  status: number;
}

export interface DeleteHostAction {
  type: HostActionEnum.REQUEST_DELETE_HOST;
  id: number;
}
export interface ResultDeleteHostAction {
  type: HostActionEnum.RESULT_DELETE_HOST;
  status: number;
}

export interface DeleteStripeIdAction {
  type: HostActionEnum.REQUEST_DELETE_STRIPE_ID;
  id: number;
}
export interface ResultDeleteStripeIdAction {
  type: HostActionEnum.RESULT_DELETE_STRIPE_ID;
  data: string;
  status: number;
}

export interface ClearHostResultAction {
  type: HostActionEnum.CLEAR_HOST_RESULT;
}

export type HostAction =
  | GetHostsAction
  | ResultGetHostsAction
  | ErrorHostAction
  | AddHostAction
  | ResultAddHostAction
  | UpdateHostAction
  | ResultUpdateHostAction
  | DeleteHostAction
  | ResultDeleteHostAction
  | ClearHostResultAction
  | DeleteStripeIdAction
  | ResultDeleteStripeIdAction;
