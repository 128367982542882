import { Backdrop, Modal } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import ModalAction from 'src/components/Items/modal/Actions';
import { useActions } from 'src/hooks/useActions';
import { IOrderFetch } from 'src/models/IOrder';
import FormOrderModal from '../Form';

interface IProps {
  order: IOrderFetch;
  open: boolean;
  handleClose: () => void;
  table?: boolean;
  fields: any[];
}

const UpdateOrderModal: FC<IProps> = ({
  order,
  open,
  handleClose,
  table,
  fields,
}) => {
  const { updateOrder } = useActions();
  const [currentOrder, setCurrentOrder] = useState(order);
  const { id, status, note, payment_link } = order;
  const handleInput = (name: keyof IOrderFetch) => (value: string | number) =>
    setCurrentOrder({ ...currentOrder, [name]: value });

  const handleSubmit = () => updateOrder(currentOrder);
  useEffect(() => {
    setCurrentOrder({ ...currentOrder, id, status, note, payment_link });
  }, [order]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <>
        <div className='modal modal-850'>
          <div className='modal__title'>Edit Order</div>
          <FormOrderModal
            order={currentOrder}
            setOrder={handleInput}
            table={table}
            fields={fields}
          />
          <ModalAction
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            type='order'
            action='Update'
          />
        </div>
      </>
    </Modal>
  );
};
export default UpdateOrderModal;
