import { FC, useEffect, useState } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import HostFormModal from './HostForm';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { validateEmail, validateField, validatePassword, validatePhone } from 'src/common/utils/validation';
import { Host } from 'src/entity/Host';
import { IHostFetch } from 'src/models/IHost';

interface IProps {
  open: boolean;
  handleClose: () => void;
  editHost?: IHostFetch;
}

export type IErrorsHostData = {
  email?: string | undefined;
  last_name?: string | undefined;
  first_name?: string | undefined;
  phone?: string | undefined;
  country?: string | undefined;
  city?: string | undefined;
  host_tag?: string | undefined;
  password?: string | undefined;
};

const HostModal: FC<IProps> = ({ open, handleClose, editHost }) => {
  const { updateHost, addHost } = useActions();
  const [host, setHost] = useState<Host | IHostFetch>(new Host());
  const [disable, setDisable] = useState(true);
  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState<IErrorsHostData>({});

  useEffect(() => {
    if (editHost !== undefined) {
      setHost(editHost);
    }
  }, [editHost]);

  const handleInput = (name: string) => (value: string | number) => {
    if (name === 'host_tag') {
      setHost({ ...host, 'host_tag': value.toString().toUpperCase() });
    } else if (name === 'email') {
      setHost({ ...host, 'email': value.toString().toLowerCase() });
    } else {
      setHost({ ...host, [name]: value });
    }
  };

  const validateInputs = (): boolean => {
    const first_name = editHost ? '' : validateField(host.first_name);
    const last_name = editHost ? '' : validateField(host.last_name);
    const email = editHost ? '' : validateEmail(host.email);
    const phone = editHost ? '' : validatePhone(String(host.phone));
    // const host_tag = editHost ? validateField(host.host_tag[0]) : validateField(host.host_tag);
    // const password = (editHost && !host.password) ? '' : validatePassword(host.password!, host.email);
    const host_tag = editHost ? '' : validateField(host.host_tag);
    const password = editHost ? '' : validatePassword(host.password!, host.email);
    setErrors({
      first_name,
      last_name,
      email,
      phone,
      host_tag,
      password
    });
    return [first_name, last_name, email, phone, host_tag, password].every(
      (el) => !el
    );
  };

  useEffect(() => {
    const updatedHost = host as any;
    updatedHost.password === '' && delete updatedHost.password;
    JSON.stringify(editHost) === JSON.stringify(updatedHost) ?
      setDisable(true) : setDisable(false);
  }, [host]);

  const handleCloseModal = () => {
    editHost ? setHost(editHost) : setHost(new Host());
    setCheck(false);
    setErrors({});
    handleClose();
  };

  const handleSubmit = () => {
    setCheck(true);
    if (validateInputs()) {
      setHost(new Host());
      setCheck(false);
      setErrors({});
      handleCloseModal();
      if (editHost) {
        const updatedHost = host;
        for (const key in host) {
          (host[key as keyof typeof host] === editHost[key as keyof typeof editHost] ||
            (key !== 'stripe_connect_id' && host[key as keyof typeof host] === '')
          ) && delete updatedHost[key as keyof typeof updatedHost];
        }
        updateHost(updatedHost as IHostFetch, editHost.id);
      } else addHost(host);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-850'>
          <div className='modal__title'>{editHost ? 'Edit Host' : 'Add Host'}</div>
          <HostFormModal host={host} setHost={handleInput} check={check} errors={errors} />
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={handleCloseModal}
              sx={{ marginTop: '30px' }}
            />
            <Button
              sx={{ marginTop: 3.5 }}
              variant='contained'
              onClick={handleSubmit}
              children={editHost ? 'Edit Host' : 'Add Host'}
              disabled={editHost ? disable : false}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default HostModal;
