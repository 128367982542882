import { FC, useEffect, useState } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import LocationFormModal from './LocationForm';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { validateField } from 'src/common/utils/validation';
import { Location } from "src/entity/Location";
import { ITag } from 'src/store/reducers/tag/types';
import { ILocationFetch } from 'src/models/ILocation';

interface IProps {
  open: boolean;
  handleClose: () => void;
  editLocation?: ILocationFetch;
}

export type IErrorsLocationData = {
  name?: string | undefined;
  review_link?: string | undefined;
  country?: string | undefined;
};

const LocationModal: FC<IProps> = ({ open, handleClose, editLocation }) => {

  const { addLocation, updateLocation } = useActions();
  const {
    tag: { tagList },
  } = useTypedSelector((state) => state);
  const [location, setLocation] = useState<ILocationFetch | Location>(new Location());
  const [disable, setDisable] = useState(true);
  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState<IErrorsLocationData>({});
  const regionTags = tagList.filter((tag) => (tag.category as any) === 'region');

  useEffect(() => {
    if (editLocation !== undefined) {
      setLocation(editLocation);
    }
  }, [editLocation]);

  const handleInput = (name: string) => (el: any) => {
    if (name === 'tags') {
      const tags = [] as ITag[];
      el.target.value.map((tagName: string) => (
        regionTags.find((regionTag) => (
          (regionTag.name === tagName) && tags.push(regionTag)
        ))
      ));
      setLocation({ ...location, tags: tags });
    } else setLocation({ ...location, [name]: el });
  };

  const validateInputs = (): boolean => {
    const name = validateField(location.name);
    const review_link = validateField(location.review_link);
    const country = validateField(location.country);
    setErrors({ name, review_link, country });
    return [name, review_link, country].every((el) => !el);
  };

  useEffect(() => {
    JSON.stringify(editLocation) === JSON.stringify(location) ?
      setDisable(true) : setDisable(false);
  }, [location]);

  const handleCloseModal = () => {
    editLocation ? setLocation(editLocation) : setLocation(new Location());
    setCheck(false);
    setErrors({});
    handleClose();
  };

  const handleSubmit = () => {
    setCheck(true);
    if (validateInputs()) {
      setLocation(new Location());
      setCheck(false);
      setErrors({});
      handleCloseModal();
      if (editLocation) {
        const updatedLocation = location;
        for (const key in location) {
          (location[key as keyof typeof location] === editLocation[key as keyof typeof editLocation] ||
            location[key as keyof typeof location] === ''
          ) && delete updatedLocation[key as keyof typeof updatedLocation];
        }
        updateLocation(updatedLocation as ILocationFetch, editLocation.id);
      } else addLocation(location);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-850'>
          <div className='modal__title'>{editLocation ? 'Edit Location' : 'Add Location'}</div>
          <LocationFormModal
            location={location}
            locationId={editLocation && editLocation.id}
            setLocation={handleInput}
            check={check}
            errors={errors}
          />
          <div className='col-2'>
            <SecondTypeButton
              children='Cancel'
              onClick={handleCloseModal}
              sx={{ marginTop: '30px' }}
            />
            <Button
              sx={{ marginTop: 3.5 }}
              variant='contained'
              onClick={handleSubmit}
              children={editLocation ? 'Edit Location' : 'Add Location'}
              disabled={editLocation ? disable : false}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default LocationModal;
