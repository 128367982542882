import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, Fragment, useEffect, useState } from 'react';
import Icons from 'src/common/icons';
import SecondTypeButton from '../../button/SecondTypeButton';
import SimpleInput from '../../input/SimpleInput';

interface IProps {
  addAction: any;
  title: string;
  handleClose: () => void;
}

const AddMuchModal: FC<IProps> = ({ title, handleClose, addAction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [nameArray, setNameArray] = useState(['']);
  const [item, setItem] = useState('');

  const handleChange = (index: number) => (event: any) => {
    const editedArr = [...nameArray];
    editedArr[index] = event.target.value;
    setNameArray(editedArr);
  };

  const [check, setCheck] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
    nameArray.map((n) => {
      !n && setIsError(true);
    });
  }, [nameArray]);

  const removeItem = (index: number): void => {
    setNameArray([...nameArray.slice(0, index), ...nameArray.slice(index + 1)]);
  };

  const handleSubmit = () => {
    if (isError) setCheck(true);
    else {
      enqueueSnackbar(`Adding ${title}...`);
      addAction(nameArray);
      setItem('');
      handleClose();
    }
  };

  return (
    <div className='modal modal-400'>
      <div className='modal__title'>{title}</div>
      <div className='modal__fields'>
        {nameArray.map((n: string, index: number) => (
          <Fragment key={index}>
            <div className='modal__field'>
              {index > 0 && (
                <div
                  className='modal__field_icon icon_stroke'
                  onClick={() => removeItem(index)}
                >
                  <Icons.DeleteOneRow />
                </div>
              )}
              <SimpleInput
                data={n}
                setData={handleChange(index)}
                length={50}
                key={index}
                check={check}
                index
                fullWidth
                placeholder={`${title}'s name`}
              />
            </div>
          </Fragment>
        ))}
      </div>

      <div className='col-2'>
        {
          <SecondTypeButton
            onClick={() => {
              setNameArray([...nameArray, item]);
            }}
            style={{ marginTop: '30px' }}
          >
            {`Add ${title}`}
          </SecondTypeButton>
        }
        {nameArray.length && (
          <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{ marginTop: '30px' }}
          >
            {`Save ${title}`}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddMuchModal;
