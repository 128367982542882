import React from 'react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';
import { Accordion, AccordionDetails, AccordionSummary, LinearProgress, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SmsIcon from '@mui/icons-material/Sms';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import ErrorPage from 'src/pages/Error';
import OrderNotification from '../Notification';
import CartData from './CartData';
import CommonData from './CommonData';
import CustomerData from './CustomerData';
import Docs from './Docs';
import DriverData from './Driver';
import Note from './Note';
import Payments from './Payments';
import Actions from './Actions';
import { dineroSubtractAmount } from 'src/common/utils/dataFormat';
import {
  INotification,
  OrderNotificationToGuestAdditionEnum,
  OrderNotificationToLeadsEnum,
  OrderNotificationToGuestAutomaticallyEnum,
  OrderNotificationToHostsEnum
} from 'src/store/reducers/order/types';
import './styles.scss';

const OrderShow: FC = () => {
  const {
    order: { order, error, isLoading, result, notification },
    client,
    driver
  } = useTypedSelector((state) => state);
  const notificationResult = useTypedSelector((state) => state.order.notification.result);

  const {
    getOrder,
    getExtras,
    getHosts,
    clearOrder,
    initNotification,
    getLocations,
    getPickupDetails,
    getAfterTripCharges
  } =
    useActions();
  const { id } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const clipboard = useClipboard();

  useEffect(() => {
    getExtras();
    getLocations();
    getHosts();
    getPickupDetails();
    getAfterTripCharges();
    return () => {
      clearOrder();
      initNotification();
    };
  }, []);

  const setColorSnack = (
    data: INotification,
    clientMail: string,
    type: string
  ) => {
    setTimeout(initNotification, 2000);
    if (data.status === 200) {
      return enqueueSnackbar(`${data.type} (${type}) have sent !`, {
        variant: 'success',
        style: { color: `white !important` },
      });
    } else if (data.text) return enqueueSnackbar(data.text, { variant: 'error' });
  };

  const SentSmsNotification = () => {
    notificationResult.sms === 200 ? (
      enqueueSnackbar('TEXT was sent!', {
        variant: 'success'
      })
    ) : (
      enqueueSnackbar('No Texting - use email', {
        variant: 'error'
      })
    );
  };

  useEffect(() => {
    notificationResult.smsText?.status === 200 && (
      notificationResult.smsText.data === '' ? (
        enqueueSnackbar('Sending this SMS is currently unavailable', {
          variant: 'info',
          persist: true
        })
      ) : (
        clipboard.copy(notificationResult.smsText.data),
        enqueueSnackbar('SMS text copied successfully!', {
          variant: 'success',
          persist: true
        })
      ),
      setTimeout(() => closeSnackbar(), 7000)
    );
  }, [notificationResult.smsText]);

  useEffect(() => {
    const isResultEmail = notificationResult.email && order!.id === notificationResult.email.orderId;
    isResultEmail && setColorSnack(notificationResult.email!, order!.client.email, 'email');
    notificationResult.sms && SentSmsNotification();
  }, [notificationResult.sms, notificationResult.email]);

  useEffect(() => {
    id &&
      !Object.values(isLoading).find((el) => el) &&
      !Object.values(client.isLoading).find((el) => el) &&
      getOrder(+id);
  }, [
    id,
    result.update,
    result.delete,
    result.payment,
    result.refund,
    result.action,
    result.transfer,
    client.result,
    driver.result.add,
    driver.result.update,
    isLoading.all
  ]);

  useEffect(() => {
    (
      (notification.result.email?.status && !notification.isLoading.email) ||
      (notification.result.sms && !notification.isLoading.sms)
    ) && getOrder(+id!);
  }, [notification.result.email, notification.result.sms]);

  useEffect(() => {
    result.update === 200 &&
      enqueueSnackbar(
        'Order successfully updated',
        { variant: 'success' }
      );
  }, [result.update]);

  useEffect(() => {
    result.refund === 200 &&
      enqueueSnackbar(
        'Refund was successful',
        { variant: 'success' }
      );
  }, [result.refund]);

  if (!order || (isLoading.get && !order)) return <LinearProgress />;
  if (order)
    return (
      <div className='order-show col-4'>
        <div className='order-show__common'>
          <CommonData order={order} />
        </div>
        <div className='order-show__customer'>
          <CustomerData order={order} />
        </div>
        <div className='order-show__notification'>
          <div className='order-show__title'>
            Notifications
            <Tooltip
              leaveTouchDelay={3000}
              enterTouchDelay={50}
              title={
                <React.Fragment>
                  <div className='order-show__title__tooltip'>
                    <div className='order-show__title__tooltip__item'>
                      <AlternateEmailIcon />
                      <p>Send email</p>
                    </div>
                    <div className='order-show__title__tooltip__item'>
                      <SmsIcon />
                      <p>Send TEXT</p>
                    </div>
                    <div className='order-show__title__tooltip__item'>
                      <ContentCopyIcon />
                      <p>Copy SMS text</p>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <HelpIcon style={{ fill: 'rgb(0, 127, 255)' }} />
            </Tooltip>
          </div>
          <div className='order-show__notification__item dedicated'>
            <div className='order-show__subtitle-red'>
              Do not send - system sends it
              <Tooltip
                leaveTouchDelay={3000}
                enterTouchDelay={50}
                title={
                  <React.Fragment>
                    <div className='order-show__title__tooltip'>
                      <b>
                        These notifications are sent<br />
                        automatically, but you can<br />
                        also send it manually.
                      </b>
                    </div>
                  </React.Fragment>
                }
              >
                <HelpIcon style={{ fill: 'rgb(0, 127, 255)' }} />
              </Tooltip>
            </div>
            <OrderNotification
              orderId={order.id!}
              order={order.notification}
              due={
                dineroSubtractAmount(
                  order.price,
                  order.partial_paid!,
                  order.currency
                )
              }
              status={order.status}
              OrderNotificationEnum={OrderNotificationToGuestAutomaticallyEnum}
            />
          </div>
          <div className='order-show__notification__item'>
            <div className='order-show__subtitle'>To guest | Additions</div>
            <OrderNotification
              orderId={order.id!}
              order={order.notification}
              due={
                dineroSubtractAmount(
                  order.price,
                  order.partial_paid!,
                  order.currency
                )
              }
              status={order.status}
              OrderNotificationEnum={OrderNotificationToGuestAdditionEnum}
            />
          </div>
          <div className='order-show__notification__item'>
            <div className='order-show__subtitle'>To Host & Team</div>
            <OrderNotification
              orderId={order.id!}
              order={order.notification}
              due={
                dineroSubtractAmount(
                  order.price,
                  order.partial_paid!,
                  order.currency
                )
              }
              status={order.status}
              OrderNotificationEnum={OrderNotificationToHostsEnum}
            />
          </div>
          <div className='order-show__notification__item'>
            <div className='order-show__subtitle'>To Leads</div>
            <OrderNotification
              orderId={order.id!}
              order={order.notification}
              due={
                dineroSubtractAmount(
                  order.price,
                  order.partial_paid!,
                  order.currency
                )
              }
              status={order.status}
              OrderNotificationEnum={OrderNotificationToLeadsEnum}
            />
          </div>
        </div>
        <div className='order-show__cart'>
          <CartData order={order} />
        </div>
        <div className='order-show__payment'>
          <Payments order={order} />
        </div>
        <div className='order-show__docs'>
          <Docs order={order} />
        </div>
        <div className='order-show_drivers'>
          <DriverData order={order} />
        </div>
        <div className='order-show_note'>
          <Note order={order} />
        </div>
        <div className='order-show_actions'>
          <Actions order={order} />
        </div>
      </div>
    );
  else if (error.get) return <ErrorPage />;
  else return null;
};

export default OrderShow;
