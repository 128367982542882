import { FC, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import NotificationMenuItem from './NotificationsMenuItem';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import {
    INotification,
    OrderNotificationToGuestAdditionEnum,
    OrderNotificationToLeadsEnum,
    OrderNotificationToGuestAutomaticallyEnum,
    OrderNotificationToHostsEnum,
} from 'src/store/reducers/order/types';

const notificationItems = [
    {
        title: 'Automatically Sent To Guest',
        notificationType: OrderNotificationToGuestAutomaticallyEnum
    },
    {
        title: 'To guest | Additions',
        notificationType: OrderNotificationToGuestAdditionEnum
    },
    {
        title: 'To Host & Team',
        notificationType: OrderNotificationToHostsEnum
    },
    {
        title: 'To Leads',
        notificationType: OrderNotificationToLeadsEnum
    },
];

interface IProps {
    orderId: number;
    due: number;
    orderNotifications: string[];
    status: string;
}

const NotificationMenu: FC<IProps> = ({ orderId, due, orderNotifications, status }) => {

    const { getOrder, initNotification } = useActions();
    const { enqueueSnackbar } = useSnackbar();
    const { notification } = useTypedSelector((state) => state.order);
    const notificationResult = useTypedSelector((state) => state.order.notification.result);

    const setColorSnack = (data: INotification, type: string) => {
        setTimeout(initNotification, 2000);
        if (data.status === 200) {
            return enqueueSnackbar(`${data.type} (${type}) have sent !`, {
                variant: 'success',
                style: { color: `white !important` },
            });
        } else if (data.text) return enqueueSnackbar(data.text, { variant: 'error' });
    };

    const SentSmsNotification = () => {
        notificationResult.sms === 200 ? (
            enqueueSnackbar('TEXT was sent!', {
                variant: 'success'
            })
        ) : (
            enqueueSnackbar('No Texting - use email', {
                variant: 'error'
            })
        );
    };

    useEffect(() => {
        const isResultEmail = notificationResult.email && orderId === notificationResult.email.orderId;
        isResultEmail && setColorSnack(notificationResult.email!, 'email');
        notificationResult.sms && SentSmsNotification();
    }, [notificationResult.sms, notificationResult.email, notificationResult.sms]);

    useEffect(() => {
        notification.result.email?.status &&
            !notification.isLoading.email &&
            getOrder(orderId!);
    }, [notification.result.email]);

    return (
        <>
            {notificationItems.map((item) => (
                <NotificationMenuItem
                    title={item.title}
                    orderId={orderId}
                    due={due}
                    status={status}
                    orderNotifications={orderNotifications}
                    OrderNotificationEnum={item.notificationType}
                />
            ))}
        </>
    );
};

export default NotificationMenu;
