import { Backdrop, Modal } from '@mui/material';
import { FC, useState } from 'react';
import ModalAction from 'src/components/Items/modal/Actions';
import { Client } from 'src/entity/Client';
import { useActions } from 'src/hooks/useActions';
import { IClientFetch } from 'src/models/IClient';
import ClientForm from '../Form';
import './styles.scss';

interface IProps {
  open: boolean;
  handleClose: () => void;
  orderId?: number;
}

const AddClientModal: FC<IProps> = ({ open, handleClose, orderId }) => {
  const { addClient, updateOrder } = useActions();
  const [currentClient, setCurrentClient] = useState(new Client());

  const handleInput = (name: string) => (value: string | number) => {
    if (name === 'email') {
      setCurrentClient({ ...currentClient, 'email': value.toString().toLowerCase() });
    } else {
      setCurrentClient({ ...currentClient, [name]: value });
    }
  };

  const handleSubmit = () => {
    orderId
      ? updateOrder({ id: orderId, client: currentClient as IClientFetch })
      : addClient(currentClient);
    setCurrentClient(new Client());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-550 client-modal'>
          <div className='modal__title'>Add Client</div>
          <ClientForm client={currentClient} setClient={handleInput} />
          <ModalAction
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            type='client'
            action='Add'
          />
        </div>
      </>
    </Modal>
  );
};
export default AddClientModal;
