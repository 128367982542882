import { IHost, IHostFetch } from "src/models/IHost";
import {
  AddHostAction,
  ClearHostResultAction,
  DeleteHostAction,
  DeleteStripeIdAction,
  GetHostsAction,
  HostActionEnum,
  ResultAddHostAction,
  ResultDeleteHostAction,
  ResultDeleteStripeIdAction,
  ResultGetHostsAction,
  ResultUpdateHostAction,
  UpdateHostAction
} from "./types";

export const HostActionCreators = {
  getHosts: (): GetHostsAction => ({
    type: HostActionEnum.REQUEST_HOSTS
  }),
  resultGetHosts: (data: IHostFetch[], status: number): ResultGetHostsAction => ({
    type: HostActionEnum.RESULT_HOSTS,
    data,
    status
  }),
  addHost: (data: IHost): AddHostAction => ({
    type: HostActionEnum.REQUEST_ADD_HOST,
    data,
  }),
  resultAddHost: (status: number): ResultAddHostAction => ({
    type: HostActionEnum.RESULT_ADD_HOST,
    status,
  }),
  updateHost: (currentHost: IHostFetch, id: string | number): UpdateHostAction => ({
    type: HostActionEnum.REQUEST_UPDATE_HOST,
    currentHost,
    id
  }),
  resultUpdateHost: (status: number): ResultUpdateHostAction => ({
    type: HostActionEnum.RESULT_UPDATE_HOST,
    status,
  }),
  deleteHost: (id: number): DeleteHostAction => ({
    type: HostActionEnum.REQUEST_DELETE_HOST,
    id,
  }),
  resultDeleteHost: (status: number): ResultDeleteHostAction => ({
    type: HostActionEnum.RESULT_DELETE_HOST,
    status,
  }),
  deleteStripeId: (id: number): DeleteStripeIdAction => ({
    type: HostActionEnum.REQUEST_DELETE_STRIPE_ID,
    id,
  }),
  resultDeleteStripeId: (data: string, status: number): ResultDeleteStripeIdAction => ({
    type: HostActionEnum.RESULT_DELETE_STRIPE_ID,
    data,
    status
  }),
  clearHostResult: (): ClearHostResultAction => ({
    type: HostActionEnum.CLEAR_HOST_RESULT
  }),
};
