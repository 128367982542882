import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Table from 'src/components/Items/table';
import Badge from 'src/components/Items/Badge';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import {
  dineroFormat,
  dineroIsPositive,
  fromISOtoFormat,
} from 'src/common/utils/dataFormat';
import { NewReportHostOrder } from 'src/store/reducers/report/host/types';
import { BadgeThemeOrderEnum } from 'src/models/IOrder';

const NewReportTable: FC = () => {
  const {
    hostReport: { orderList, statistic },
    host: { hostList },
  } = useTypedSelector((state) => state);

  const isStage = process.env.REACT_APP_NODE_ENV;

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        tooltip: 'Order number',
        Cell: ({
          row: {
            original: { id, status },
          },
        }: any) => (
          <div className='report-table__container'>
            <Link to={`/order/${id}`} className='react-link'>
              {id}
            </Link>
            <Badge
              text={status}
              theme={
                BadgeThemeOrderEnum[status as keyof typeof BadgeThemeOrderEnum]
              }
            />
          </div>
        ),
        width: 25,
      },
      {
        Header: 'Dates',
        accessor: 'start_date',
        tooltip: 'Trips dates',
        Cell: ({
          row: {
            original: { start_date, end_date },
          },
        }: any) => (
          <>
            <div>{fromISOtoFormat(start_date, 'MM/dd/yy')}</div>
            <div>{fromISOtoFormat(end_date, 'MM/dd/yy')}</div>
          </>
        ),
      },
      {
        Header: 'Host',
        accessor: 'host_id',
        tooltip: 'Hosts name',
        Cell: ({
          row: {
            original: {
              van: {
                host: { first_name, last_name },
              },
            },
          },
        }: any) => <>{`${first_name} ${last_name}`}</>,
      },
      {
        Header: 'Total Price',
        accessor: 'price',
        tooltip: 'Total cost of the trip',
        Cell: ({
          row: {
            original: { price, currency },
          },
        }: any) => <>{dineroFormat(price, currency)}</>,
      },
      {
        Header: 'Guest Paid',
        accessor: 'guestPaid',
        tooltip: 'Amount guest paid (all payment methods)',
        Cell: ({
          row: {
            original: { guestPaid, currency },
          },
        }: any) => <>{dineroFormat(guestPaid, currency)}</>,
      },
      {
        Header: 'Unpaid',
        accessor: 'unpaid',
        tooltip: 'Guest paid in full; no outstanding balance after the return',
        Cell: ({
          row: {
            original: { unpaid, currency },
          },
        }: any) => <>{dineroFormat(unpaid, currency)}</>,
      },
      {
        Header: 'SR Got',
        accessor: 'srGot',
        tooltip: 'Amount we received from the guest during the booking process',
        Cell: ({
          row: {
            original: { srGot, currency },
          },
        }: any) => <>{dineroFormat(srGot, currency)}</>,
      },
      {
        Header: 'Host Got',
        accessor: 'hostGot',
        tooltip: 'Amount collected by host from guest',
        Cell: ({
          row: {
            original: { hostGot, currency },
          },
        }: any) => <>{dineroFormat(hostGot, currency)}</>,
      },
      {
        Header: 'On Stripe',
        accessor: 'onStripe',
        tooltip: 'Amount transferred to a host by Stripe',
        Cell: ({
          row: {
            original: { onStripe, currency },
          },
        }: any) => <>{dineroFormat(onStripe, currency)}</>,
      },
      {
        Header: 'Stripe ID',
        accessor: 'stripe_connect_id',
        tooltip: 'ID to which the money was transferred',
        Cell: ({
          row: {
            original: {
              van: {
                host: { stripe_connect_id },
              },
            },
          },
        }: any) => (
          <Tooltip title='Open link'>
            <a
              href={
                isStage
                  ? `https://dashboard.stripe.com/test/connect/accounts/${stripe_connect_id}/activity`
                  : `https://dashboard.stripe.com/connect/accounts/${stripe_connect_id}/activity`
              }
              target='_blank'
            >
              {`${stripe_connect_id.slice(0, 4)}...`}
            </a>
          </Tooltip>
        ),
      },
      {
        Header: 'Stripe Match',
        accessor: 'stripeMatch',
        tooltip: 'Was the amount sent to the correct host?',
        Cell: ({
          row: {
            original: { stripeMatch },
          },
        }: any) => <>{stripeMatch}</>,
      },
      {
        Header: 'Transfer',
        accessor: 'transfer',
        tooltip:
          "Transfer ID is the record of where the previous host's payment was transferred to you.",
        Cell: ({
          row: {
            original: { transfer },
          },
        }: any) => (
          <>
            {transfer.includes('tr_') ? (
              <Tooltip title='Open link'>
                <a
                  href={
                    isStage
                      ? `https://dashboard.stripe.com/test/connect/transfers/${transfer}`
                      : `https://dashboard.stripe.com/connect/transfers/${transfer}`
                  }
                  target='_blank'
                >
                  {`${transfer.slice(0, 4)}...`}
                </a>
              </Tooltip>
            ) : (
              transfer
            )}
          </>
        ),
      },
      {
        Header: 'Paid Diff. Host',
        accessor: 'otherHostPay',
        tooltip:
          'The booking was swapped after a guest paid. The previous host was paid.',
        Cell: ({
          row: {
            original: { otherHostPay },
          },
        }: any) => <>{otherHostPay}</>,
      },
      {
        Header: 'Hosts 80%',
        accessor: 'host80',
        tooltip: '80% of Total Price in Dollars',
        Cell: ({
          row: {
            original: { host80, currency },
          },
        }: any) => <>{dineroFormat(host80, currency)}</>,
      },
      {
        Header: "SR's 20%",
        accessor: 'sr20',
        tooltip: '20% of Total Price in Dollars',
        Cell: ({
          row: {
            original: { sr20, currency },
          },
        }: any) => <>{dineroFormat(sr20, currency)}</>,
      },
      {
        Header: 'Debit / Credit',
        accessor: 'debitCredit',
        tooltip: 'Fee difference: agreed vs. collected for booking Us.',
        Cell: ({
          row: {
            original: { debitCredit, currency },
          },
        }) => (
          <div
            style={{
              color: `${dineroIsPositive(debitCredit) ? 'green' : 'red'}`,
            }}
          >
            {dineroFormat(debitCredit, currency)}
          </div>
        ),
      },
      {
        Header: 'CC Fees',
        accessor: 'stripeFee',
        tooltip:
          'BTW, we cover the credit card processing fees for you, even though the guest is charged. This is our commitment to help you be more profitable in this economy. However, this policy may change in the future without notice.',
        Cell: ({
          row: {
            original: { stripeFee, currency },
          },
        }) => <>{dineroFormat(stripeFee, currency)}</>,
      },
    ],
    [hostList]
  );

  return (
    <Table.Base
      data={orderList as NewReportHostOrder[]}
      columns={columns}
      class='react-table'
      total={statistic._count._all || 0}
    />
  );
};

export default NewReportTable;
