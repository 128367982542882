import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import sumBy from 'lodash/sumBy';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import PriceSelection from './PriceSelection';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import Times from 'src/components/Items/filters/Item/Times';
import Location from 'src/components/Items/filters/Item/Location';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroAdd, dineroDivide, dineroMultiply, dineroSubtractAmount } from 'src/common/utils/dataFormat';
import { IOrderFetch } from 'src/models/IOrder';
import './styles.scss';

interface IProps {
  handleClose: () => void;
}

const MainOrderForm: FC<IProps> = ({ handleClose }) => {
  const { updateOrder, changeOrderDates } = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const oldOrder = useTypedSelector((state) => state.order.order!);
  const pds = useTypedSelector((state) => state.pickupDetails.pickupDetailsList);

  const [newOrder, setNewOrder] = useState<IOrderFetch>(oldOrder);
  const [disable, setDisable] = useState(true);
  const [showPrice, setShowPrice] = useState(false);
  const [defaultStart, setDefaultStart] = useState(false);
  const [defaultEnd, setDefaultEnd] = useState(false);

  useEffect(() => {
    setNewOrder(oldOrder);
    !oldOrder!.return_at &&
      setNewOrder({
        ...oldOrder,
        return_at: 'Same as delivery'
      });
  }, [oldOrder]);

  useEffect(() => {
    newOrder.location && setDefaultStart(false);
    newOrder.end_location && setDefaultEnd(false);
  }, [newOrder.location, newOrder.end_location]);

  useEffect(() => {
    if (defaultStart) {
      if (newOrder.sub_location) {
        const pickups = pds.filter((pd) => (
          pd.location === `${newOrder.location}__${newOrder.sub_location}`
        ));
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === newOrder.van.host_id
        ));
        if (pdWithHostId) {
          setNewOrder({
            ...newOrder,
            delivery_address: pdWithHostId.address_take
          });
        } else if (pickups.length) {
          setNewOrder({
            ...newOrder,
            delivery_address: pickups[0].address_take
          });
        } else {
          setNewOrder({
            ...newOrder,
            delivery_address: ''
          });
          enqueueSnackbar('No suitable default start locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      } else {
        const pickups = pds.filter((pd) => pd.location === newOrder.location);
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === newOrder.van.host_id
        ));
        if (pdWithHostId) {
          setNewOrder({
            ...newOrder,
            delivery_address: pdWithHostId.address_take
          });
        } else if (pickups.length) {
          setNewOrder({
            ...newOrder,
            delivery_address: pickups[0].address_take
          });
        } else {
          setNewOrder({
            ...newOrder,
            delivery_address: ''
          });
          enqueueSnackbar('No suitable default start locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      }
    } else {
      setNewOrder({
        ...newOrder,
        delivery_address: oldOrder.delivery_address
      });
    }
  }, [defaultStart]);

  useEffect(() => {
    if (defaultEnd) {
      if (newOrder.sub_location_end) {
        const pickups = pds.filter((pd) => (
          pd.location === `${newOrder.end_location
            ? newOrder.end_location
            : newOrder.location
          }__${newOrder.sub_location_end}`
        ));
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === newOrder.van.host_id
        ));
        if (pdWithHostId) {
          setNewOrder({
            ...newOrder,
            return_at: pdWithHostId.address_drop
          });
        } else if (pickups.length) {
          setNewOrder({
            ...newOrder,
            return_at: pickups[0].address_drop
          });
        } else {
          setNewOrder({
            ...newOrder,
            return_at: ''
          });
          enqueueSnackbar('No suitable default end locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      } else {
        const pickups = pds.filter((pd) => (
          newOrder.end_location
            ? pd.location === newOrder.end_location
            : pd.location === newOrder.location
        ));
        const pdWithHostId = pickups.find((pd) => (
          pd.host_id && pd.host_id === newOrder.van.host_id
        ));
        if (pdWithHostId) {
          setNewOrder({
            ...newOrder,
            return_at: pdWithHostId.address_drop
          });
        } else if (pickups.length) {
          setNewOrder({
            ...newOrder,
            return_at: pickups[0].address_drop
          });
        } else {
          setNewOrder({
            ...newOrder,
            return_at: ''
          });
          enqueueSnackbar('No suitable default end locations found', {
            variant: 'info',
            persist: true,
          });
          setTimeout(() => closeSnackbar(), 7000);
        }
      }
    } else {
      setNewOrder({
        ...newOrder,
        return_at: oldOrder.return_at
      });
    }
  }, [defaultEnd]);

  const handleChangePrice = () => {
    const newDate = DateTime.fromISO(newOrder.end_date!)
      .diff(DateTime.fromISO(newOrder.start_date!), ['days', 'hours'])
      .toObject();

    const { hours, days } = newDate;
    let vanPrice = dineroMultiply(oldOrder.van.price, newDate.days!);

    if (hours && days) {
      if (hours <= 6 && hours > 0) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(oldOrder.van?.price!, 4),
          oldOrder.currency!
        );
      } else if (hours > 6 && hours <= 12) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroDivide(oldOrder.van?.price!, 2),
          oldOrder.currency!
        );
      } else if (hours > 12 && hours <= 18) {
        vanPrice = dineroAdd(
          vanPrice,
          dineroSubtractAmount(
            oldOrder.van?.price!,
            dineroDivide(oldOrder.van?.price!, 4),
            oldOrder.currency!
          ),
          oldOrder.currency!
        );
      } else if (hours > 18 && hours <= 24) {
        vanPrice = dineroAdd(
          vanPrice,
          oldOrder.van?.price!,
          oldOrder.currency!
        );
      }
    }

    if (!vanPrice) vanPrice = oldOrder.van.price;

    const extrasPrice = sumBy(oldOrder.fee_list, 'price');
    setNewOrder({
      ...newOrder,
      price: dineroAdd(
        vanPrice,
        extrasPrice,
        oldOrder.currency!
      )
    });
  };

  const handleChange = (name: string) => (value: string) =>
    setNewOrder({ ...newOrder, [name]: value });

  useEffect(() => {
    const sameReturn = (newOrder.return_at === 'Same as delivery')
      ? '' : newOrder.return_at;
    (newOrder.start_date === oldOrder.start_date &&
      newOrder.end_date === oldOrder.end_date &&
      newOrder.location === oldOrder.location &&
      newOrder.sub_location === oldOrder.sub_location &&
      newOrder.sub_location_end === oldOrder.sub_location_end &&
      newOrder.end_location === oldOrder.end_location &&
      newOrder.delivery_address === oldOrder.delivery_address &&
      sameReturn === oldOrder.return_at
    ) ? setDisable(true) : setDisable(false);
  }, [newOrder.start_date,
  newOrder.end_date,
  newOrder.location,
  newOrder.end_location,
  newOrder.sub_location,
  newOrder.sub_location_end,
  newOrder.delivery_address,
  newOrder.return_at
  ]);

  useEffect(() => {
    (newOrder.start_date === oldOrder.start_date &&
      newOrder.end_date === oldOrder.end_date) ?
      setShowPrice(false) : (
        setShowPrice(true),
        handleChangePrice()
      );
  }, [newOrder.start_date, newOrder.end_date]);

  const changeOrder = () => {
    const updatedDates = { start_date: newOrder.start_date, end_date: newOrder.end_date };
    const updatedLocations = {
      location: newOrder.location,
      end_location: newOrder.end_location,
      sub_location: newOrder.sub_location,
      sub_location_end: newOrder.sub_location_end,
      delivery_address: newOrder.delivery_address,
      return_at: newOrder.return_at
    };

    for (const key in updatedDates) {
      updatedDates[key as keyof typeof updatedDates] === oldOrder[key as keyof typeof oldOrder]
        && delete updatedDates[key as keyof typeof updatedDates];
    }
    for (const key in updatedLocations) {
      (
        updatedLocations[key as keyof typeof updatedLocations] === oldOrder[key as keyof typeof oldOrder]
        || (key === 'return_at' && updatedLocations.return_at === 'Same as delivery')
      ) && delete updatedLocations[key as keyof typeof updatedLocations];
    }

    if (Object.keys(updatedDates).length) {
      changeOrderDates(
        {
          period: {
            start_date: newOrder.start_date,
            end_date: newOrder.end_date
          },
          feeList: newOrder.fee_list
        },
        oldOrder.id
      );
    }

    if (Object.keys(updatedLocations).length) {
      updateOrder({ ...updatedLocations, id: oldOrder.id });
    }

    setDisable(true);
    handleClose();
  };

  return (
    <div className='order-form'>
      <Times check={false} order={newOrder} setOrder={setNewOrder} />
      <Location order={newOrder} setOrder={setNewOrder} />
      <div>
        <SimpleInput
          data={newOrder.delivery_address!}
          setData={handleChange('delivery_address')}
          title='Delivery to'
          variant='outlined'
          length={200}
          multiline
        />
        <div className='order-form__checkbox'>
          <CustomCheckbox
            onChange={() => setDefaultStart(!defaultStart)}
            checked={defaultStart}
            name='Check to use default: Start Location'
          />
          <span>Check to use default: Start Location</span>
        </div>
      </div>
      <div>
        <SimpleInput
          data={newOrder.return_at!}
          setData={handleChange('return_at')}
          title='Return at'
          variant='outlined'
          length={200}
          multiline
        />
        <div className='order-form__checkbox'>
          <CustomCheckbox
            onChange={() => setDefaultEnd(!defaultEnd)}
            checked={defaultEnd}
            name='Check to use default: End Location'
          />
          <span>Check to use default: End Location</span>
        </div>
      </div>
      {showPrice &&
        <PriceSelection
          oldPrice={oldOrder.price}
          newPrice={newOrder.price!}
          currency={oldOrder.currency!}
        />
      }
      <div className='col-2'>
        <SecondTypeButton
          children='Cancel'
          onClick={handleClose}
        />
        <Button
          variant='contained'
          onClick={changeOrder}
          children={'Edit Order'}
          disabled={disable}
        />
      </div>
    </div>
  );
};

export default MainOrderForm;
