import { FC } from 'react';
import { Modal, Backdrop } from '@mui/material';
import MainOrderForm from './MainOrderForm';

interface IProps {
  handleClose: () => void;
  open: boolean;
}

const BookDateOrderModal: FC<IProps> = ({ handleClose, open }) => {

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={handleClose}
    >
      <div className='modal modal-550'>
        <div className='modal__title'>Edit Booking date</div>
        <MainOrderForm handleClose={handleClose} />
      </div>
    </Modal>
  );
};
export default BookDateOrderModal;
