import { FC, useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { CircularProgress, Tooltip } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ActionForm from 'src/components/Items/ActionForm';
import Table from 'src/components/Items/table';
import HostModal from 'src/pages/Admin/Modals/AddHost';
import DeleteModalForm from 'src/components/Items/DeleteModalForm';
import HostStaffModal from '../../Modals/HostStaff';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useShowSnackbar from "src/hooks/useShowSnackbar";
import { IHostFetch } from 'src/models/IHost';

const HostTable: FC = () => {

  const { enqueueSnackbar } = useSnackbar();
  const { getHosts, clearHostResult } = useActions();
  const {
    host: { hostList, isLoading, total, result },
  } = useTypedSelector((state) => state);
  const hostStaffResult = useTypedSelector((state) => state.hostStaff.result);
  useShowSnackbar(isLoading, result, clearHostResult, 'Host');

  const [selectedHost, setSelectedHost] = useState({} as IHostFetch);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  // const [openStaff, setOpenStaff] = useState(false);

  useEffect(() => {
    getHosts();
  }, []);

  useEffect(() => {
    (
      result.add ||
      result.update ||
      result.delete ||
      result.deleteStripe ||
      hostStaffResult.add ||
      hostStaffResult.update ||
      hostStaffResult.delete
    ) && getHosts();
  }, [
    result.add,
    result.update,
    result.delete,
    result.deleteStripe,
    hostStaffResult.add,
    hostStaffResult.update,
    hostStaffResult.delete
  ]);

  useEffect(() => {
    (selectedHost && result.all && (
      hostStaffResult.add || hostStaffResult.update || hostStaffResult.delete
    )) && (
        hostList.map((host) => {
          (host.id === selectedHost.id) && setSelectedHost(host);
        })
      );
  }, [result.all]);

  useEffect(() => {
    result.deleteStripe && (
      enqueueSnackbar(`${result.deleteStripe}`, {
        variant: 'success'
      }),
      clearHostResult()
    );
  }, [result.deleteStripe]);

  const handleOpen = (host: IHostFetch, type: string) => {
    // type === 'edit' ? setOpenEdit(true) : setOpenStaff(true);
    type === 'edit' ? setOpenEdit(true) : setOpenDelete(true);
    setSelectedHost(host);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }: any) => <>{id}</>,
        width: 25,
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({
          row: {
            original: { first_name, last_name },
          },
        }: any) => {
          return <>{`${first_name} ${last_name}`}</>;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({
          row: {
            original: { email },
          },
        }: any) => {
          return <>{email}</>;
        },
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        Cell: ({
          row: {
            original: { phone },
          },
        }: any) => {
          return <>{phone}</>;
        },
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell: ({
          row: {
            original: { country },
          },
        }: any) => {
          return <>{country}</>;
        },
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: ({
          row: {
            original: { state },
          },
        }: any) => {
          return <>{state}</>;
        },
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: ({
          row: {
            original: { city },
          },
        }: any) => {
          return <>{city}</>;
        },
      },
      {
        Header: 'Home address',
        accessor: 'home_address',
        Cell: ({
          row: {
            original: { home_address },
          },
        }: any) => {
          return <>{home_address}</>;
        },
      },
      {
        Header: 'Nearest airport',
        accessor: 'nearest_airport',
        Cell: ({
          row: {
            original: { nearest_airport },
          },
        }: any) => {
          return <>{nearest_airport}</>;
        },
      },
      {
        Header: 'Company name',
        accessor: 'company_name',
        Cell: ({
          row: {
            original: { company_name },
          },
        }: any) => {
          return <>{company_name}</>;
        },
      },
      {
        Header: 'Host tag',
        accessor: 'host_tag',
        Cell: ({
          row: {
            original: { host_tag },
          },
        }: any) => {
          return <>{host_tag.join(', ')}</>;
        },
      },
      {
        Header: 'Stripe connect',
        accessor: 'stripe_connect_id',
        Cell: ({
          row: {
            original: { stripe_connect_id },
          },
        }: any) => {
          return (
            <>{stripe_connect_id && `${stripe_connect_id.slice(0, 4)}...`}</>
          );
        },
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC' style={{ justifyContent: 'right' }}>
              <Tooltip title='Edit order'>
                <ActionForm.IconSet
                  title='order'
                  classWrapper='action-wrapper'
                  onEdit={() => handleOpen(original, 'edit')}
                  onDelete={() => handleOpen(original, 'delete')}
                />
              </Tooltip>
              {/* <Tooltip title='Staff'>
                <PeopleAltOutlinedIcon
                  className='icon_stroke'
                  strokeWidth={0}
                  sx={{ fontSize: '1.8rem' }}
                  onClick={() => handleOpen(original, 'staff')}
                />
              </Tooltip> */}
            </div>
          </>
        ),
      },
    ],
    [hostList]
  );

  return (
    <>
      <HostModal
        open={openEdit}
        editHost={selectedHost}
        handleClose={() => setOpenEdit(false)}
      />
      <DeleteModalForm
        id={selectedHost.id}
        type='host'
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
      />
      {/* <HostStaffModal
        open={openStaff}
        host={selectedHost}
        handleClose={() => setOpenStaff(false)}
      /> */}
      {isLoading.all ? (
        <CircularProgress
          size={200}
          style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
        />
      ) : hostList.length ? (
        <div className='order-table'>
          <Table.Base
            data={hostList}
            columns={columns}
            class='react-table'
            total={total!}
          />
        </div>
      ) : null}
    </>
  );
};
export default HostTable;
