import { put, takeLatest } from "redux-saga/effects";
import instance from "../set";
import { HostActionCreators } from "src/store/reducers/host/action-creators";
import { HostActionEnum } from "src/store/reducers/host/types";


function* fetchDeleteStripeId({
  id,
}: ReturnType<typeof HostActionCreators.deleteStripeId>) {
  try {
    const { data, status } = yield instance.get(`host/stripe-delete/${id}`);
    yield put(HostActionCreators.resultDeleteStripeId(data, status));
  } catch (e) {
    console.log(e);
  }
}
export function* actionWatcherDeleteStripeId() {
  yield takeLatest(HostActionEnum.REQUEST_DELETE_STRIPE_ID, fetchDeleteStripeId);
}
