import { requestState, responseState } from "../utils";
import { HostAction, HostActionEnum, HostState } from "./types";

const initialState: HostState = {
  hostList: [],
  total: null,
  isLoading: {
    all: false,
    get: false,
    add: false,
    delete: false,
    update: false,
    deleteStripe: false
  },
  result: {},
};
const host = (state = initialState, action: HostAction): HostState => {
  switch (action.type) {
    case HostActionEnum.REQUEST_HOSTS:
      return {
        ...state,
        isLoading: { ...state.isLoading, all: true },
        result: {}
      };
    case HostActionEnum.RESULT_HOSTS:
      return {
        ...state,
        hostList: action.data,
        isLoading: { ...state.isLoading, all: !action.data },
        result: { all: action.status }
      };
    case HostActionEnum.REQUEST_ADD_HOST:
      return requestState(state, "add");

    case HostActionEnum.RESULT_ADD_HOST:
      return responseState(state, "add", action);

    case HostActionEnum.REQUEST_UPDATE_HOST:
      return requestState(state, "update");

    case HostActionEnum.RESULT_UPDATE_HOST:
      return responseState(state, "update", action);

    case HostActionEnum.REQUEST_DELETE_HOST:
      return requestState(state, "delete");

    case HostActionEnum.RESULT_DELETE_HOST:
      return responseState(state, "delete", action);

    case HostActionEnum.CLEAR_HOST_RESULT:
      return { ...state, result: {} };

    case HostActionEnum.REQUEST_DELETE_STRIPE_ID:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteStripe: true },
        result: {}
      };

    case HostActionEnum.RESULT_DELETE_STRIPE_ID:
      return {
        ...state,
        isLoading: { ...state.isLoading, deleteStripe: false },
        result: { deleteStripe: action.data }
      };

    default:
      return state;
  }
};
export default host;
