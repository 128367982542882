import { FC, useState } from 'react';
import { DateTime } from 'luxon';
import { Skeleton, Tooltip } from '@mui/material';
import CartItem from './Item';
import { dineroFormat } from 'src/common/utils/dataFormat';
import ArticlesModal from './Modal';
import { IOrderFetch } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  order: IOrderFetch;
}

const CartData: FC<IProps> = ({ order }) => {
  const { extras, van, price, currency, end_date, start_date, fee_list } =
    order;

  let days = Math.ceil(
    DateTime.fromISO(end_date!)
      .diff(DateTime.fromISO(start_date!), ['days'])
      .toObject().days!
  );
  if (days && days <= 1) {
    days = 1;
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const _orderItems = () => (
    <>
      {van ? (
        <CartItem item={van} title='van' days={days} />
      ) : (
        <Skeleton width='100%' height='55px' />
      )}
      {extras &&
        fee_list.map((extra, i) => (
          <CartItem item={extra} title='extra' index={i} />
        ))}
    </>
  );

  return (
    <>
      <div className='order-show__title extras-title'>
        Articles
        <span className='order-cart__subtitle'>
          click on amount to edit rates
        </span>
        <Tooltip title='Edit extras'>
          <div
            onClick={handleOpen}
            className='order-show__title__action icon_stroke'
          >
            <Icons.Edit />
          </div>
        </Tooltip>
      </div>
      <div className='order-cart__wrapper'>{_orderItems()}</div>
      <div className='order-cart__wrapper-total'>
        <div className='order-cart__wrapper-total__info'>
          Incl. All Fees:
          <div className='extra-card__price'>{`Total ${dineroFormat(
            price,
            currency
          )}`}</div>
        </div>
      </div>
      <ArticlesModal
        open={open}
        handleClose={handleClose}
        order={order}
      />
    </>
  );
};

export default CartData;
