import { Backdrop, Modal } from '@mui/material';
import { FC, useState } from 'react';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import ModalAction from 'src/components/Items/modal/Actions';

interface IProps {
  open: boolean;
  handleClose: () => void;
  smsText: string;
  name?: string;
  orderId?: number;
  disable: boolean;
}

const SmsModal: FC<IProps> = ({
  open,
  handleClose,
  smsText, name,
  orderId,
  disable
}) => {

  // const { sendSms } = useActions();
  const [sms, EditSms] = useState(smsText);

  // const handleSubmit = () => {
  //   orderId && (
  //     sendSms(orderId, sms),
  //     handleClose()
  //   );
  // };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      sx={{ zIndex: '9999' }}
    >
      <>
        <div className='modal modal-850'>
          <div className='modal__title'>
            {`Send ${name ? name : 'sms'}`}
          </div>
          <div className='col-1'>
            <SimpleInput
              setData={EditSms}
              data={sms}
              multiline
              rows={6}
              variant='outlined'
            />
          </div>
          <ModalAction
            handleSubmit={handleClose}
            handleClose={handleClose}
            type={name ? name : 'sms'}
            action='Send'
            smsText={sms}
            disable={disable}
          />
        </div>
      </>
    </Modal>
  );
};
export default SmsModal;
