import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import Icons from 'src/common/icons';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import { useActions } from 'src/hooks/useActions';
import { ITag } from 'src/store/reducers/tag/types';

interface IProps {
  handleClose: () => void;
  categories: string[];
}

const AddTagModal: FC<IProps> = ({ handleClose, categories }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { addTag } = useActions();

  const initTag: ITag = {
    name: '',
    category: 'location' as any,
  };

  const [data, setData] = useState<ITag[]>([{ ...initTag }]);
  const [item, setItem] = useState<ITag>({ ...initTag });

  const handleChange =
    (index: number, key: keyof Omit<ITag, 'id'>) =>
      (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
        const editedArr: any[] = [...data];
        editedArr[index][key] = key === 'name' ? event.target.value : event;
        setData(editedArr);
      };

  const [check, setCheck] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
    data.map(({ name, category }) => {
      (!name || !category) && setIsError(true);
    });
  }, [data]);

  const removeItem = (index: number) => () =>
    setData([...data.slice(0, index), ...data.slice(index + 1)]);
  const handleAddMore = () => setData([...data, { ...item }]);

  const handleSubmit = () => {
    if (isError) setCheck(true);
    else {
      enqueueSnackbar('Adding tags...');
      addTag(data);
      setItem({ ...initTag });
      handleClose();
    }
  };

  return (
    <div className='modal modal-400 modal-tag'>
      <div className='modal__title'>Tags</div>
      <div className='modal__fields'>
        {data.map(({ name, category }, index: number) => (
          <Fragment key={index}>
            <div className='modal__field'>
              {index > 0 && (
                <div
                  className='modal__field_icon icon_stroke'
                  onClick={removeItem(index)}
                >
                  <Icons.DeleteOneRow />
                </div>
              )}
              <SimpleInput
                data={name}
                setData={handleChange(index, 'name')}
                length={50}
                key={index}
                check={check}
                index
                fullWidth
                placeholder="tag's name"
              />
              <SimpleSelect
                data={category}
                setData={handleChange(index, 'category')}
                dataArray={categories}
              />
            </div>
          </Fragment>
        ))}
      </div>
      <div className='col-2'>
        <SecondTypeButton onClick={handleAddMore} children='Add tags' style={{ marginTop: '30px' }} />

        {data.length && (
          <Button
            variant='contained'
            children='Save tags'
            onClick={handleSubmit}
            sx={{ marginTop: '30px' }}
          />
        )}
      </div>
    </div>
  );
};

export default AddTagModal;
