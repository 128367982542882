import { FC, useEffect, useState } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import CustomCheckbox from 'src/components/Items/button/CustomCheckbox';
import PickupDetailsFormModal from './PickupDetailsForm';
import { useActions } from 'src/hooks/useActions';
import { validateField } from 'src/common/utils/validation';
import { PickupDetails } from 'src/entity/PickupDetails';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export type IErrorsPdData = {
  location?: string | undefined;
  return_at?: string | undefined;
  description?: string | undefined;
  host_id?: string | undefined;
  for_all?: boolean | undefined;
  address_take?: string | undefined;
  address_drop?: string | undefined;
};

const AddPickupDetailsModal: FC<IProps> = ({ open, handleClose }) => {

  const { addPickupDetails } = useActions();
  const [pd, setPd] = useState<PickupDetails>(new PickupDetails());
  const [mainLocation, setMainLocation] = useState('');
  const [check, setCheck] = useState(false);
  const [errors, setErrors] = useState<IErrorsPdData>({});
  const [disable, setDisable] = useState(false);

  const handleInput = (name: string) => (value: any) => {
    name === 'location' ? (
      value.location ? (
        setPd({ ...pd, 'location': value.name }),
        setMainLocation(value.location)
      ) : (
        setPd({ ...pd, [name]: value }),
        setMainLocation('')
      )
    ) : (
      setPd({ ...pd, [name]: value })
    );
  };

  function chengeCheckbox() {
    setPd({ ...pd, 'for_all': !pd.for_all });
  }

  useEffect(() => {
    pd.for_all ? (
      setDisable(true),
      setPd({ ...pd, 'host_id': null })
    ) : setDisable(false);
  }, [pd.for_all]);

  const validateInputs = (): boolean => {
    const location = validateField(pd.location);
    const return_at = validateField(pd.return_at);
    const description = validateField(pd.description);
    const address_take = validateField(pd.address_take);
    const address_drop = validateField(pd.address_drop);
    const host_id = pd.for_all
      ? '' : (pd.host_id === null
        ? 'Field can not be empty'
        : '');

    setErrors({
      location,
      return_at,
      host_id,
      description,
      address_take,
      address_drop
    });
    return [location, return_at, host_id, description, address_take, address_drop].every(
      (el) => !el
    );
  };

  const handleCloseModal = () => {
    setPd(new PickupDetails());
    setCheck(false);
    setErrors({});
    handleClose();
  };

  const handleSubmit = () => {
    setCheck(true);
    if (validateInputs()) {
      setPd(new PickupDetails());
      setCheck(false);
      setErrors({});
      handleCloseModal();
      mainLocation
        ? addPickupDetails({
          ...pd, 'location': `${mainLocation}__${pd.location}`
        })
        : addPickupDetails(pd);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <div className='modal modal-850'>
        <div className='modal__title'>Add Pickup Details</div>
        <PickupDetailsFormModal
          pd={pd}
          setPd={handleInput}
          check={check}
          errors={errors}
          disable={disable}
        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginLeft: '-5px' }}>
          <CustomCheckbox
            onChange={chengeCheckbox}
            checked={pd.for_all}
            name={'for all'}
          />
          <p className='filter__title' style={{ marginBottom: 0, marginLeft: '10px' }}>Default</p>
        </div>
        <div className='col-2'>
          <SecondTypeButton
            children='Cancel'
            onClick={handleCloseModal}
            sx={{ marginTop: '30px' }}
          />
          <Button
            sx={{ marginTop: 3.5 }}
            variant='contained'
            onClick={handleSubmit}
            children={'Add Pickup Details'}
          />
        </div>
      </div>
    </Modal>
  );
};
export default AddPickupDetailsModal;
