import { Backdrop, Modal, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import Icons from "src/common/icons";
import { IBase } from "src/models/types";
import SecondTypeButton from "../../button/SecondTypeButton";
import AddMuchModal from "../AddMuchModal";

interface IProps {
  actions: { add: any; delete: any };
  title: string;
  data: IBase[];
  handleClose: () => void;
}

const AllModal: FC<IProps> = ({ data, title, handleClose, actions }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  return (
    <div className="modal modal-850">
      <div className="modal__title">{title}</div>
      <div className="modal__items ">
        {data.map(({ name, id }) => (
          <div
            className="modal__item van-card__tags__item"
            key={id}
            title={name}
          >
            {name}
            <Tooltip title="delete">
              <div className="icon_stroke" onClick={() => actions.delete(id)}>
                <Icons.Cross />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
      <SecondTypeButton onClick={handleOpenAdd} children="Add more" style={{ marginTop: '20px' }} />
      <Modal
        open={openAdd}
        onClose={handleCloseAdd}
        BackdropComponent={Backdrop}
        closeAfterTransition
      >
        <>
          <AddMuchModal
            addAction={actions.add}
            title={title}
            handleClose={handleCloseAdd}
          />
        </>
      </Modal>
    </div>
  );
};

export default AllModal;
