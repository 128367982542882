import { Currency } from "dinero.js";
import { FC, useEffect, useState } from "react";
import { Backdrop, Modal } from "@mui/material";
import SimpleInput from "src/components/Items/input/SimpleInput";
import SimpleSelect from "src/components/Items/select/SimpleSelect";
import NumberInput from "src/components/Items/input/NumberInput";
import ModalAction from "src/components/Items/modal/Actions";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import { INearVan } from "src/models/INearVan";
import { IVan, IVanFetch } from "src/models/IVan";
import { Van } from "src/entity/Van";

interface IProps {
  van: IVanFetch;
  handleClose: () => void;
  open: boolean;
}

const CloneVanModal: FC<IProps> = ({ open, van, handleClose }) => {

  const {
    van: { isLoading, result },
  } = useTypedSelector((state) => state);
  const createdVan = useTypedSelector((state) => state.van.van);

  const [currentVan, setCurrentVan] = useState(new Van(van));
  const [isCloned, setIsCloned] = useState(false);
  const { addVan, updateVan, addNearVans } = useActions();

  useEffect(() => {
    const newArray: INearVan[] = [];
    !isLoading.add && isCloned &&
      (result.add === 200 || result.add === 201) && (
        van.near_van?.length && (
          van.near_van.map((nv) => (
            newArray.push({
              price: nv.price,
              currency: nv.currency,
              location: nv.location,
              van_id: createdVan!.id
            })
          )
          )),
        updateVan(
          {
            id: createdVan!.id,
            link: `https://van.sprinter-rentals.com/van/143${createdVan!.id}`
          } as IVanFetch,
          createdVan!.id
        ),
        addNearVans(newArray),
        setIsCloned(false)
      );
  }, [createdVan]);

  const handleInput = (name: string) => (value: string | number) =>
    setCurrentVan({ ...currentVan, [name]: value });

  const currencyTypeArray: Currency[] = ["USD", "EUR"];
  const [price, setPrice] = useState(van.price! / 100);

  const _renderInput = (
    name: keyof Pick<IVan, "currency" | "mark">,
    title: string = name,
    length: number = 30
  ) => (
    <SimpleInput
      data={currentVan[name]!}
      setData={handleInput(name)}
      title={title}
      length={length}
    />
  );

  const vanModel = {
    ...currentVan,
    price: price * 100,
  };

  const handleClone = () => addVan(vanModel) && setIsCloned(true);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <>
        <div className="modal modal-850">
          <div className="modal__title">Copy Van</div>
          <div className="col-2">
            {_renderInput("mark")}
            <div className="col-2">
              <NumberInput data={price} setData={setPrice} title="Price" text />
              <SimpleSelect
                data={currentVan.currency}
                setData={handleInput("currency")}
                title="currency"
                dataArray={currencyTypeArray}
              />
            </div>
          </div>
          <ModalAction
            handleClose={handleClose}
            handleSubmit={handleClone}
            type="van"
            action="Clone"
          />
        </div>
      </>
    </Modal>
  );
};

export default CloneVanModal;
