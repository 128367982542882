import { FC } from 'react';
import { IconButton, InputAdornment, styled, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
  data: string | number;
  setData: (event: string) => void;
  title?: string;
  placeholder?: string;
  length?: number;
  disabled?: boolean;
  tooltip: string
  onClick: () => void;
}

const StyledInput = styled(TextField)({
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    'WebkitAppearance': 'none',
    margin: 0
  },
  'input[type=number]': {
    'MozAppearance': 'textfield'
  }
});

const InputWithButton: FC<IProps> = ({
  data,
  setData,
  title,
  placeholder = title,
  length,
  disabled,
  tooltip,
  onClick
}) => {

  return (
    <div>
      <div className='filter__title'>
        {title}
      </div>
      <StyledInput
        inputProps={{
          maxLength: length,
        }}
        value={data ?? ''}
        onChange={(event) => setData(event.target.value)}
        placeholder={placeholder}
        variant='standard'
        InputProps={{
          endAdornment:
            !disabled ? (
              <Tooltip title={tooltip}>
                <InputAdornment position='end' onClick={onClick}>
                  <IconButton sx={{ w: 30, h: 30 }}>
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            ) : <></>
        }}
      />
    </div>
  );
};
export default InputWithButton;
