import { FC, useState } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import DriverForm from '../Form';
import { validateField } from 'src/common/utils/validation';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useShowSnackbar from 'src/hooks/useShowSnackbar';
import { useActions } from 'src/hooks/useActions';
import { Driver } from 'src/entity/Driver';
import { IClientFetch } from 'src/models/IClient';

export type IErrorsDriverData = {
  first_name?: string | undefined;
  last_name?: string | undefined;
  email?: string | undefined;
};

interface IProps {
  open: boolean;
  handleClose: () => void;
  orderId: number;
  client: IClientFetch;
}

const AddDriverModal: FC<IProps> = ({
  open,
  handleClose,
  orderId,
  client: { id },
}) => {
  const { addDriver, clearDriverResult } = useActions();
  const {
    driver: { isLoading, result },
  } = useTypedSelector((state) => state);

  const [driver, setDriver] = useState(new Driver());
  const [errors, setErrors] = useState<IErrorsDriverData>({});

  useShowSnackbar(isLoading, result, clearDriverResult, 'Driver');

  const handleInput = (name: string) => (value: string | number) => {
    if (name === 'email') {
      setDriver({ ...driver, 'email': value.toString().toLowerCase(), client_id: id });
    } else {
      setDriver({ ...driver, [name]: value, client_id: id });
    }
  };

  const validateInputs = (): boolean => {
    const first_name = validateField(driver.first_name);
    const last_name = validateField(driver.last_name);
    const email = validateField(driver.email);
    setErrors({ first_name, last_name, email });
    return [first_name, last_name, email].every(
      (el) => !el
    );
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      addDriver(driver, orderId);
      setDriver(new Driver());
      setErrors({});
      handleClose();
    }
  };

  const handleCloseModal = () => {
    setDriver(new Driver());
    setErrors({});
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
    >
      <>
        <div className='modal modal-550'>
          <div className='modal__title'>Add Driver</div>
          <DriverForm
            setDriver={handleInput}
            data={driver}
            errors={errors}
          />
          <div className={'col-2'} style={{ marginTop: '30px' }}>
            <SecondTypeButton
              children='Cancel'
              onClick={handleCloseModal}
            />
            <Button
              variant='contained'
              onClick={handleSubmit}
              children='Add driver'
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default AddDriverModal;
